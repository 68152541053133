import React/* , { useState, useEffect } */ from 'react'

// import SectionOne from './SectionOne'
import SectionTwo from '../../home/SectionTwo';
import SectionThree from '../../home/SectionThree';
import SectionFour from '../../home/SectionFour';
import Restaurants from '../../home/Restaurants';
import Informacion from '../informacion/index';
import Prices from '../prices'
import { makeStyles } from '@material-ui/core/styles';

import Footer from '../../../components/Footer'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}))
function Home(props) {
    const classes = useStyles()
    // const URL = ('https://bt4187j4yl.execute-api.us-east-1.amazonaws.com/prod/getCountry')
    // const request = axios.get(URL);
    // console.log(request)
    // const [posts, setPosts] = useState([]);
    // useEffect(() => {
    //     axios.get('https://bt4187j4yl.execute-api.us-east-1.amazonaws.com/prod/getCountry').then((res) => {
    //         setPosts(res);
    //         console.log(posts);
    //     })
    // });
    
    return (
        <div className={classes.root}>
            <Informacion  {...props }/>
            {/* <SectionOne /> */}
            <Restaurants />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <Prices />
            <Footer/>
        </div>
    )
}

export default Home;