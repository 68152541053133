import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Button,
    Grid,
    useMediaQuery,
    Container,
    CssBaseline,
    Hidden
} from '@material-ui/core';
import {ReactComponent as SVGLogoDark} from '../../components/Icons/logo-dark.svg';
import axios from 'axios';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
        // height: '100vh',
        display: 'flex',
        background: '#FDF5ED',
        paddingBottom: '10%',
        paddingTop: '10%',
    },
    containerSub3: {
        width: '50%', 
        display: 'flex', 
        justifyContent: 'center',
    },
    boxSub3: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
    },
    imgLogo: {
        width: 136,
        height: 106,
        alignSelf: 'flex-end',
        display: 'flex',
    },
    imgLogoQuery: {
        width: 80,
        height: 'auto',
        margin: '0 auto',
        paddingTop: 20,
        // alignSelf: 'flex-end',
        display: 'flex',
    },
    imgMobile: {
        width: 400,
        height: 'auto',
    },
    boxImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    question: {
        fontSize: 48,
        fontFamily: 'Muli',
        fontWeight: '400',
        // lineHeight: '72px',
        color: '#000000',
        letterSpacing: '-0.015em',
    },
    questionQuery: {
        fontSize: 20,
        fontFamily: 'Muli',
        fontWeight: '400',
        // lineHeight: '72px',
        color: '#000000',
        letterSpacing: '-0.015em',
    },
    response: {
        fontSize: 21,
        fontFamily: 'Muli',
        fontWeight: '400',
        // lineHeight: '30px',
        letterSpacing: '-0.015em',
        marginBottom: 20,
        color: 'rgba(117, 112, 110, 0.8)'
    },
    responseQuery: {
        fontSize: 15,
        fontFamily: 'Muli',
        fontWeight: '400',
        // lineHeight: '30px',
        letterSpacing: '-0.015em',
        marginBottom: 20,
        color: 'rgba(117, 112, 110, 0.8)'
    },
    boton2 :{
        fontWeight: '600',
        fontFamily: 'Muli',
        fontSize: 14,
        height: 40,
        background: '#FFB500',
        textAlign: 'center',
        width: 'fit-content',
        borderRadius: 6,
        boxShadow: 'none',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    boxInfo: {
        display: 'flex', 
        alignItems: 'center',
        textAlign: 'right',
    },
    boxInfoQuery: {
        display: 'flex', 
        alignItems: 'center',
        textAlign: 'center',
    },
    boxMobile: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    boxMobileQuery: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
    },
    mobile: {
        width: '65%',
        // height: 'auto',
    },
    mobileQuery: {
        width: 200,
        // height: 'auto',
    },
    mobileIphone: {
        width: 150,
        paddingBottom: 20
        // height: 'auto',
    },
}));

const URL = 'https://cw9v27zz62.execute-api.us-east-1.amazonaws.com/production/getCountry'

const SectionThree = () => {
    const classes = useStyles();
    const location = useLocation();
    const fullScreen = useMediaQuery('@media (max-width: 960px)');
    const iphoneScreen = useMediaQuery('@media (max-width: 320px)');
    const [country, setCountry] = React.useState('')

    const handleTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

    React.useEffect(() => {
		async function fetch() {
			try {
				const result = await axios.get(URL)
                // console.log('result', result.data.country)
                const img = result.data.country
                await require(`../../../public/assets/modelPhone/${img}.png`) 
                setCountry(img)
			} catch (error) {
                // console.log('error', error)             
                setCountry('CO')
			}
		}
		fetch()
	}, [])

	return (
		<div className={classes.root} style={{ marginTop: fullScreen ? 50 : 0 }}>
            <CssBaseline />
            <Container>
                <Grid container xs={12} sm={12} lg={12}> 
                    <Grid xs={12} sm={6} lg={6} className={ fullScreen ? classes.boxInfoQuery : classes.boxInfo}>

                        <Grid container>
                            <Grid xs={12} sm={4} lg={4}>
                                {/* <img src='assets/logo-dark.png' alt='logo' className={ fullScreen ? classes.imgLogoQuery : classes.imgLogo}/> */}
                                <SVGLogoDark alt='logo' className={ fullScreen ? classes.imgLogoQuery : classes.imgLogo}/>
                            </Grid>
                            <Grid xs={12} sm={8} lg={8}>
                                <Typography className={ fullScreen ? classes.questionQuery : classes.question}>¿Estás listo para crecer tus ventas?</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} lg={12}>
                                <Typography className={ fullScreen ? classes.responseQuery : classes.response}>
                                    Los clientes entran a tu propia tienda en línea, realizan el pedido 
                                    de forma fácil e intuitiva, al final del proceso el pedido será enviado
                                    a través de WhatsApp a la sucursal del restaurante.
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={12} lg={12}>
                                <Hidden smDown>
                                    <Button 
                                        variant="contained" 
                                        className={classes.boton2} 
                                        style={{alignSelf: 'flex-end'}}
                                        href={location.pathname === "/landing" ? '' : "https://aliados.reunidos.app/register"}
                                        target={location.pathname === "/landing" ? null : "_blank"}
                                        onClick={ location.pathname === "/landing" ? handleTop : ''}
                                    >
                                        {location.pathname === "/landing" ? 'Empezar' : 'Comenzar ahora'}
                                    </Button>
                                </Hidden>
                            </Grid>
                        </Grid>
                            
                    </Grid>
                    <Grid xs={12} sm={6} lg={6} className={ fullScreen ? classes.boxMobileQuery : classes.boxMobile} >
                        {/* <img src={img} alt='phone' className={ iphoneScreen ? classes.mobileIphone : fullScreen ? classes.mobileQuery : classes.mobile} /> */}
                        <img
                            src={`assets/modelPhone/${country}.png`}
                            className={ iphoneScreen ? classes.mobileIphone : fullScreen ? classes.mobileQuery : classes.mobile}
                            alt="telefono"
                        />
                        <Hidden mdUp>
                            <Button 
                                variant="contained" 
                                className={classes.boton2} 
                                href="https://aliados.reunidos.app/register" 
                                target="_blank"
                                style={{marginTop: 20}}
                            >
                                Empezar
                            </Button>
                        </Hidden>
                    </Grid>
                </Grid>
            </Container>
		</div>
	);
}
export default SectionThree;