import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Typography,
	Grid,
	Button,
	useMediaQuery
} from '@material-ui/core';
import api from '../../services/constants/api';
import priceFormat from '../../utils/priceFormat';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// height: '100vh',
		display: 'flex',
		paddingTop: '15%',
        paddingBottom: '15%',

		backgroundImage: 'url(assets/bgPrimaryPrice.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		// backgroundSize: 'cover',
		animation: '$shrink 5s infinite alternate',
	},
	rootQuery: {
		width: '100%',
		// height: '100vh',
		display: 'flex',
		paddingTop: '30%',
		paddingBottom: '15%',
		
		backgroundImage: 'url(assets/bgPrimaryPrice.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		// animation: '$shrink 5s infinite alternate',
	},
	'@keyframes shrink': {
		'0%': {
			backgroundSize: '110% 110%',
		},
		'100%': {
			backgroundSize: '100% 100%',
		},
	},
	container: {
		width: '80%',
		margin: 'auto',
		textAlign: 'right',
	},
	title: {
		color: '#FFB500',
		fontSize: 56,
		fontFamily: 'Muli',
		fontWeight: '400',
		marginBottom: 20,
		lineHeight: '55px',
	},
	titleQuery: {
		color: '#FFB500',
		fontSize: 36,
		fontFamily: 'Muli',
		fontWeight: '400',
		lineHeight: '35px',
	},
	textInfo: {
		fontSize: 36,
		fontFamily: 'Muli',
		fontWeight: '400',
		color: '#FFFFFF'
	},
	textInfoQuery: {
		fontSize: 24,
		fontFamily: 'Muli',
		fontWeight: '400',
		color: '#FFFFFF'
	},
	price1: {
		fontSize: 40,
		fontFamily: 'Muli',
		fontWeight: '400',
		lineHeight: '40px',
		letterSpacing: '-0.015em',
		color: '#FAB400'
	},
	price1Query: {
		fontSize: 24,
		fontFamily: 'Muli',
		fontWeight: '400',
		lineHeight: '40px',
		letterSpacing: '-0.015em',
		color: '#FAB400'
	},
	price2: {
		fontSize: 30,
		fontFamily: 'Muli',
		fontWeight: '400',
		lineHeight: '40px',
		letterSpacing: '-0.015em',
		color: '#DADADA',
		marginBottom: 40,
	},
	price2Query: {
		fontSize: 24,
		fontFamily: 'Muli',
		fontWeight: '400',
		lineHeight: '40px',
		letterSpacing: '-0.015em',
		color: '#DADADA',
		marginBottom: 20,
	},
	info1: {
		fontSize: 24,
		fontFamily: 'Muli',
		fontWeight: '400',
		// lineHeight: '30px',
		letterSpacing: '-0.015em',
		color: '#DADADA'
	},
	info1Query: {
		fontSize: 18,
		fontFamily: 'Muli',
		fontWeight: '400',
		lineHeight: '20px',
		letterSpacing: '-0.015em',
		color: '#DADADA'
	},
	info2: {
		fontSize: 24,
		fontFamily: 'Muli',
		fontWeight: '400',
		// lineHeight: '30px',
		letterSpacing: '-0.015em',
		color: '#FAB400'
	},
	info2Query: {
		fontSize: 18,
		fontFamily: 'Muli',
		fontWeight: '400',
		lineHeight: '20px',
		letterSpacing: '-0.015em',
		color: '#FAB400'
	},
	boton2: {
		fontWeight: '600',
		fontSize: 14,
		fontFamily: 'Muli',
		height: 40,
		background: '#FFB500',
		textAlign: 'center',
        width: 'fit-content',
		borderRadius: 6,
		alignSelf: 'flex-end',
		'&:hover': {
			background: '#000',
			color: '#FFB500',
			border: '1px solid #FFAD00',
		},
	},
	textPrice: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	textPriceQuery: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	progress: {
		marginBottom: 40,
		'& .MuiCircularProgress-colorPrimary': {
			color: '#FFB500',
		},
	},
}));

const URL = 'https://cw9v27zz62.execute-api.us-east-1.amazonaws.com/production/getCountry'

const SectionOne = () => {
	const classes = useStyles();
	const tabletScreen = useMediaQuery('@media (max-width: 768px)');
	const fullScreen = useMediaQuery('@media (max-width: 425px)');
	const sizeScreen = useMediaQuery('@media (max-width: 360px)');
	const [tarifas, setTarifas] = useState()
	const [lang, setLang] = useState('')
	const [loading, setLoading] = useState(true)


	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [])

	useEffect(() => {
		async function fetch() {
			try {
				const result = await axios.get(URL)
				// console.log('result', result)
				// console.log('result', result.data.country)
				traerValores(result.data.country)
			} catch (error) {
				// console.log('Error al traer el pais', error)
			}
		}
		fetch()
	}, [])

	async function traerValores(pais) {
		// me trae lastarifas de acuerdo aeste pais
		// console.log(pais)
		const URLDB = `${api.baseUrl}${api.endpoints.tarifas}${pais}`
		try {
			const result = await axios.get(URLDB)
			// console.log('resultdatabase', result)
			setTarifas(result.data)	
			setLang(result.data.countries.cnt_simbol)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			// console.log('Error al traer pais', error)
		}
	}

	return (
		<div className={fullScreen ? classes.rootQuery : classes.root}>
			<Grid container className={classes.container} style={{paddingTop: tabletScreen ? '5%' : 0}}>
				<Grid xs={12} sm={12} lg={12}>
					<Typography className={fullScreen ? classes.titleQuery : classes.title}>¡Reunidos es así de simple!</Typography>
					<div style={{ marginBottom: sizeScreen ? 0 : 25 }}>
						<Typography className={fullScreen ? classes.textInfoQuery : classes.textInfo}>
							Tu menú WhatsApp en tu
						</Typography>
						<Typography className={fullScreen ? classes.textInfoQuery : classes.textInfo}>
							propia tienda online
						</Typography>
					</div>
					{/* <div>
						{loading ?
							<CircularProgress size={50} className={classes.progress}/>
						: 
							<div className={fullScreen ? classes.textPriceQuery : classes.textPrice}>
								<Typography className={fullScreen ? classes.price1Query : classes.price1}>{lang ? lang : '$'}{priceFormat(tarifas ? tarifas.trf_value : 29000)} /<span style={{ color: '#DADADA' }}> mes</span></Typography>
							</div>
						}
						{loading ?
							null
						: 
							<Typography className={fullScreen ? classes.price2Query : classes.price2}>+ {lang ? lang : '$'}{tarifas ? tarifas.trf_value_order : 800} por órden</Typography>
						}
					</div> */}
					<Button variant="contained" className={classes.boton2} style={{ marginBottom: sizeScreen ? 25 : 50 }} href="https://aliados.reunidos.app/register" target="_blank">
						Comenzar ahora
					</Button>
					<div className={classes.infoText}>
						<Typography className={fullScreen ? classes.info1Query : classes.info1}>¿Tienes más de 1.000 ordenes por mes?</Typography>
						<Typography className={fullScreen ? classes.info2Query : classes.info2}>Pregunta por los descuentos por volúmen</Typography>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}
export default SectionOne;