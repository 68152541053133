import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import Dialog from './Dialog';

import {ReactComponent as SVGLogoDark} from '../Icons/logo-dark.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "transparent",
        padding: 10
    },
    marginRight: theme.spacing(2),
    toolbar: {
        justifyContent: 'space-between',
    },
    imgLogo: {
        transition: '1s',
        width: 100,
        height: 'auto',
        // marginLeft: 20,
        paddingTop: 20,
    },
    imgLogoSvg: {
        transition: '1s',
        width: 100,
        height: 'auto',
        // marginLeft: 20,
        paddingTop: 20,
    },
    imgLogo2: {
        transition: '1s',
        width: 80,
        height: 'auto',
        // marginLeft: 20
    },
    price: {
        fontWeight: '500',
        fontSize: 15,
        fontFamily: 'Muli',
        color: '#FFFFFF',
        marginRight: 20,
        marginBottom: 10,
        textDecoration: 'none',
        height: 40,
        width: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'none',
            borderRadius: 6,
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    priceCustom: {
        fontWeight: '500',
        fontSize: 15,
        fontFamily: 'Muli',
        color: '#808080',
        marginRight: 20,
        textDecoration: 'none',
        height: 40,
        width: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
        boxShadow: 'none',
        '&:hover': {
            cursor: 'pointer',
            borderRadius: 6,
            textDecoration: 'none',
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    botonCustom :{
        color: '#FFB500',
        background: 'transparent',
        border: 'solid 1px #FFB500',
        fontWeight: '500',
        fontSize: 14,
        fontFamily: 'Muli',
        height: 40,
        alignSelf: 'center',
        borderRadius: 6,
        marginRight: 20,
        marginBottom: 10,
        boxShadow: 'none',
        textAlign: 'center',
        width: 'fit-content',
        '&:hover': {
            background: '#000',
            color: '#FFB500'
        },
    },
    botonColor :{
        color: '#FFFFFF',
        background: '#FFB500',
        fontWeight: '500',
        fontSize: 14,
        fontFamily: 'Muli',
        marginBottom: 10,
        height: 40,
        alignSelf: 'center',
        borderRadius: 6,
        boxShadow: 'none',
        textAlign: 'center',
        width: 'fit-content',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    textActive: {
        borderBottom: '1px solid #FFAD00',
        color: '#FFAD00',
    },
    menuButton: {
        marginRight: 20,
    }
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function Header(props) {
    const { elev } = props;
    const location = useLocation();
    const tabletScreen = useMediaQuery('@media (max-width: 768px)');
    const [open, setOpen] = React.useState(false)
    const classes = useStyles()
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: elev ? window() : undefined,
    });
    const toggle = () => setOpen(!open)

    return (
        <div className={classes.toolbar}>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar className={classes.root} style={{ backgroundColor: trigger ? 'white' : 'transparent' }}>
                    <Toolbar style={{justifyContent:  tabletScreen && location.pathname === "/landing" ? 'center' : location.pathname === "/landing" ? 'flex-start' : 'space-between'}}>
                        <Link to={location.pathname === "/landing" ? '/landing' : '/'}>
                            {trigger ? 
                                <SVGLogoDark className={classes.imgLogo2} style={{marginLeft: location.pathname === "/landing" ? '' : 20}} />
                            : 
                                location.pathname === '/como-hacer-crecer-tu-negocio-de-restaurante' || '/si-vendes-comida-por-whatsapp-debes-saber-esto' ? 
                                <SVGLogoDark className={classes.imgLogoSvg} style={{marginLeft: location.pathname === "/landing" ? '' : 20}} />
                                :
                                <img src='assets/logo.png' alt='logo' className={classes.imgLogo} style={{marginLeft: location.pathname === "/landing" ? '' : 20}} />
                            }
                        </Link>
                        {location.pathname === "/landing" ?
                            null
                            :
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Hidden lgUp>
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggle}>
                                    <MenuRoundedIcon fontSize='large' color='primary' />
                                </IconButton>
                            </Hidden>
                            <Hidden mdDown>
                                <Typography  className={trigger ? classes.priceCustom : classes.price} classes={{ root: location.pathname === "/precios" ? classes.textActive : null }} component={Link} to="/precios" >
                                    Precios
                                </Typography >
                            </Hidden>
                            <Hidden mdDown>
                                <Button variant="contained" className={classes.botonCustom} href="https://aliados.reunidos.app/login" target="_blank">
                                        iniciar sesión
                                </Button>
                            </Hidden>
                            <Hidden mdDown>
                                <Button variant="contained" className={classes.botonColor} href="https://aliados.reunidos.app/register" target="_blank">
                                    comenzar ahora
                                </Button>
                            </Hidden>
                        </div>
                        }
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            {/* <Toolbar className={classes.toolbar} /> */}
            <Dialog {...props} open={open} toggle={toggle} />
        </div>
    );
}
