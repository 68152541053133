import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Box, Typography, Tabs, Tab, AppBar, useMediaQuery } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import ReunidosAliados from './ReunidosAliados';
import UsoPlataforma from './UsoPlataforma';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // height: '95%',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        // paddingTop: '5%',
        paddingBottom: '5%',
        flexDirection: 'column',

        backgroundImage: 'url(assets/bgPrimary.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        animation: '$shrink 5s infinite alternate',
    },
    '@keyframes shrink': {
        '0%': {
            backgroundSize: '110% 110%',
        },
        '100%': {
            backgroundSize: '100% 100%',
        },
    },
    tabsCustom :{

        '& button.Mui-selected' :{
            backgroundColor: '#FDF5ED'
        }
    },
    // rootTabs: {
    //     backgroundColor: '#fff',
    //     width: 500,
    // },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Terms = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const fullScreen = useMediaQuery('@media (max-width: 450px)');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <p style={{ color: '#FFF', fontWeight: 'bold', fontSize: 30, marginTop: fullScreen? 110 : 80, textAlign: 'center' }}>Términos y Condiciones</p>
            <AppBar position="static" color="default" style={{zIndex: 1, width: '95%'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    className={classes.tabsCustom}
                >
                    <Tab label="Términos del servicio de Comercios Aliados" {...a11yProps(0)} />
                    <Tab label="Términos y Condiciones de Uso de Plataforma 'REUNIDOS'" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <ReunidosAliados/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <UsoPlataforma/>
                </TabPanel>
            </SwipeableViews>
        </div>
    )
}

export default Terms;