import React from "react";
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import jssExtend from "jss-plugin-extend";
import rtl from "jss-rtl";

import Theme from "./components/Theme";
import Header from "./components/Header";
// import Footer from "./components/Footer";

import Home from "./pages/home";
import NoMatch from "./pages/noMatch";
import TermsConditions from './pages/termsConditions';
import PoliticasPrivacidad from './pages/politicasPrivacidad';
import Landing from './pages/landing/home';
import Precios from './pages/prices';
import Contacted from './pages/contacted';
import News1 from './pages/news1';
import News2 from './pages/news2';

import history from "./history";
import AppContext from './AppContext';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend(), rtl()],
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const generateClassName = createGenerateClassName();

export default function App(props) {
  
  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <AppContext.Provider>
        <Theme>
          <Router history={history}>
            <Header {...props} />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/terminos-condiciones">
                <TermsConditions />
              </Route>
              <Route path="/politicas-privacidad">
                <PoliticasPrivacidad />
              </Route>
              <Route path="/landing">
                <Landing {...props}/>
              </Route>
              <Route path="/precios">
                <Precios />
              </Route>
              <Route path="/contacted">
                <Contacted {...props}/>
              </Route>
              <Route path="/como-hacer-crecer-tu-negocio-de-restaurante">
                <News1 {...props}/>
              </Route>
              <Route path="/si-vendes-comida-por-whatsapp-debes-saber-esto">
                <News2 {...props}/>
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
            
          </Router>
        </Theme>
      </AppContext.Provider>
    </StylesProvider>
  );
}
