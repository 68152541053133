import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Typography,
    Grid,
    useMediaQuery,
    Hidden,
} from '@material-ui/core';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '5%',
        paddingBottom: '5%',

        backgroundImage: 'url(assets/bgPrimary.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        animation: '$shrink 5s infinite alternate',
    },
    rootQuery: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 20,

        backgroundImage: 'url(assets/bgPrimary.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'center',
    },
    '@keyframes shrink': { 
        '0%': {
            backgroundSize: '110% 110%',
        },
        '100%': {
            backgroundSize: '100% 100%',
        },
    },
    boxMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxMobileQuery: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 105,
    },
    containerText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'right',
    },
    mobile: {
        width: '100%',
        // height: 'auto',
    },
    mobileQuery: {
        width: 200,
        // height: 'auto',
    },
    mobileIphone: {
        width: 150,
        paddingBottom: 20
        // height: 'auto',
    },
    boton2 :{
        fontWeight: '600',
        fontSize: 14,
        textAlign: 'center',
        fontFamily: 'Muli',
        height: 40,
        background: '#FFB500',
        width: 'fit-content',
        borderRadius: 6,
        boxShadow: 'none',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    title:{
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 56,
        fontFamily: 'Muli',
        lineHeight: '80px',
        // marginBottom: 20,
    },
    titleIphone:{
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 56,
        fontFamily: 'Muli',
        lineHeight: '20px',
        marginBottom: 10,
        textAlign: 'center',
    },
    titlePhone6:{
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 56,
        fontFamily: 'Muli',
        lineHeight: '20px',
        // marginBottom: 10,
        textAlign: 'center',
    },
    titleQuery:{
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 30,
        fontFamily: 'Muli',
        lineHeight: '66px',
        // marginBottom: 10,
        textAlign: 'center',
    },
    textInfo: {
        fontSize: 24,
        fontFamily: 'Muli',
        fontWeight: '400',
        color: '#FFFFFF',
        lineHeight: '30px',
        marginBottom: 20,
    },
    textInfoIphone: {
        fontSize: 24,
        fontFamily: 'Muli',
        fontWeight: 'bold',
        color: '#FFFFFF',
        lineHeight: '18px',
        marginBottom: 20,
        textAlign: 'center',
    },
    textInfoQuery: {
        fontSize: 15,
        fontFamily: 'Muli',
        fontWeight: '400',
        color: '#FFFFFF',
        lineHeight: '25px',
        marginBottom: 20,
        textAlign: 'center',
    },
    fullInfo: {
        display: 'flex', 
        maxWidth: '70%',
    },
    fullInfoQuery: {
        display: 'flex', 
        width: '80%'
    },
    progress: {
        color: '#FFB500',
		'& .MuiCircularProgress-colorPrimary': {
			color: '#FFB500',
		},
	},
}));

const URL = 'https://cw9v27zz62.execute-api.us-east-1.amazonaws.com/production/getCountry'

function SectionOne() {
    const classes = useStyles();
    const fullScreen = useMediaQuery('@media (max-width: 1024px)');
    const tabletScreen = useMediaQuery('@media (max-width: 768px)');
    const sizeText = useMediaQuery('@media (max-width: 480px)');
    const iphoneScreen = useMediaQuery('@media (max-width: 320px)');
    const iphone6Screen = useMediaQuery('@media (max-width: 375px)');
    const [loading, setLoading] = useState(true)
    const [country, setCountry] = useState('')
    React.useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

	React.useEffect(() => {
		async function fetch() {
			try {
				const result = await axios.get(URL)
                // console.log('result', result.data.country)
                const img = result.data.country
                await require(`../../../public/assets/modelPhone/${img}.png`) 
                setCountry(img)
                setLoading(false)
			} catch (error) {
                // console.log('error', error)             
                setCountry('CO')
                setLoading(false)
			}
		}
		fetch()
	}, [])

    return (
        <div className={ tabletScreen ? classes.rootQuery : classes.root}>
            <Grid container className={ fullScreen ? classes.fullInfoQuery : classes.fullInfo}>
                <Grid xs={12} sm={6} lg={6} className={ fullScreen ? classes.boxMobileQuery : classes.boxMobile}>
                    <Hidden mdUp>
                        <Typography className={ iphoneScreen ? classes.titleIphone : iphone6Screen ? classes.titlePhone6 : fullScreen ? classes.titleQuery : classes.title} style={{ fontSize: sizeText ? 20 : 56, lineHeight: sizeText ? '25px' : '60px', marginTop: 15 }}>¡Tu propio menú WhatsApp en minutos!</Typography>
                    </Hidden>
                    <div style={{width: '65%'}}>
                        {loading ?
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <CircularProgress size={80} className={classes.progress}/>
                            </div>
						:
                            // <img src={img} alt='foto movil' className={ iphoneScreen ? classes.mobileIphone : fullScreen ? classes.mobileQuery : classes.mobile} />
                            <img
                                src={`assets/modelPhone/${country}.png`}
                                className={ iphoneScreen ? classes.mobileIphone : fullScreen ? classes.mobileQuery : classes.mobile}
                                alt="telefono"
                            />
                        }
                    </div>
                    {/* <img src='assets/modelPhone.png' alt='phone' className={ iphoneScreen ? classes.mobileIphone : fullScreen ? classes.mobileQuery : classes.mobile }/> */}
                </Grid>
                <Grid xs={12} sm={6} lg={6} className={classes.containerText}>
                    <Hidden smDown>
                        <Typography className={ iphoneScreen ? classes.titleIphone : iphone6Screen ? classes.titlePhone6 : fullScreen ? classes.titleQuery : classes.title} style={{ fontSize: sizeText ? 18 : 56 }}>¡Tu propio menú WhatsApp en minutos!</Typography>
                    </Hidden>
                    <Typography className={ iphoneScreen ? classes.textInfoIphone : fullScreen ? classes.textInfoQuery : classes.textInfo} style={{ fontSize: sizeText ? 15 : 24, fontWeight: sizeText ? 'bold' : '' }}>
                        Ofrece tu menú de platos y productos con
                        una mejor experiencia al cliente, convirtiendo
                        las conversaciones de WhatsApp en pedidos.
                    </Typography>
                    <Button 
                        variant="contained" 
                        className={classes.boton2} 
                        style={{ alignSelf: fullScreen ? 'center' : 'flex-end' }} 
                        href="https://aliados.reunidos.app/register" 
                        target="_blank"
                    >
                        Comenzar ahora
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
export default SectionOne;