import React/* , { useState, useEffect } */ from 'react'

import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionThree from './SectionThree'
import SectionFour from './SectionFour'
import Restaurants from './Restaurants'
import { makeStyles } from '@material-ui/core/styles';

import Footer from '../../components/Footer'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}))
function Home() {
    const classes = useStyles()
    // const URL = ('https://bt4187j4yl.execute-api.us-east-1.amazonaws.com/prod/getCountry')
    // const request = axios.get(URL);
    // console.log(request)
    // const [posts, setPosts] = useState([]);
    // useEffect(() => {
    //     axios.get('https://bt4187j4yl.execute-api.us-east-1.amazonaws.com/prod/getCountry').then((res) => {
    //         setPosts(res);
    //         console.log(posts);
    //     })
    // });
    
    return (
        <div className={classes.root}>
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <Restaurants />
            <Footer/>
        </div>
    )
}

export default Home;