import React from 'react';
import { red } from '@material-ui/core/colors';
import { skyBlue } from '../../colors';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		alignSelf: 'center',
		backgroundColor: theme.palette.background.paper,
		height: '100vh',
	},
}));

function Theme(props) {
	const classes = useStyles();

	const theme = createMuiTheme({
		typography:{
			fontFamily: 'Muli'
		},
		palette: {
			type: 'light',
			primary: {
				main: '#F3A808'
			},
			secondary: {
				light: skyBlue[100],
				main: skyBlue[500],
				dark: skyBlue[900]
			},
			background: {
				paper: '#FFFFFF',
				// default: '#F7F7F7',
				paperDark: '#F9F9F9'
			},
			text: {
				primary: '#312927',
				secundary: '#6F6967',
				main: '#FFB008',
				textPopup: '#606060',
				verify: '#A9B6BE',
				input: '#EBEBEB',
				render: '#75706E',
				secundaryPopup: '#716B69'
			},	
			button: {
				light: '#FEE17B',
				dark: '#FFAD00',
				textBlack: '#161313',
				textSecundary: '#F3A808'
			},
			header: {
				title: '#76706E',
				active: '#726C6B',
				noActive: '#B7B4B4',
			},
			spinner: {
				primary: '#FFB008'
			},
			error: red
		},
		status: {
			danger: 'orange'
		}
    })

	return <ThemeProvider theme={theme}>
		<div className={classes.root}>
			{props.children}
		</div>
	</ThemeProvider>;
}

export default React.memo(Theme);
