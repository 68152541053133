import React from 'react'

import Informacion from './informacion';
import { makeStyles } from '@material-ui/core/styles';

import Footer from '../../components/Footer';
import { useLocation } from 'react-router-dom';
import history from '../../history';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}))

function Contacted(props) {
    const location = useLocation()
    // console.log('sttttttt location', location)
    // console.log('sttttttt location.state', location.state)
    // console.log('sttttttt', params)
    // console.log('props de contacted', props)
    const classes = useStyles();

    React.useEffect(() => {
        if(location && location.state === undefined) {
            history.push('/landing')
        }
    },[])

    return (
        <div className={classes.root}>
            <Informacion {...props}/>
            <Footer/>
        </div>
    )
}

export default Contacted;