import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Container,
    Button,
    Grid,
    useMediaQuery,
    CssBaseline,
} from '@material-ui/core';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
        // height: '100vh',
        display: 'flex',
        background: 'white',
        paddingBottom: '10%',
        paddingTop: '10%',
    },
	rootLaptop: {
		width: '100%',
        // height: '100vh',
        display: 'flex',
        background: 'white',
        paddingBottom: '10%',
        paddingTop: '10%',
    },
    containerSub3: {
        width: '50%', 
        display: 'flex', 
        justifyContent: 'center',
    },
    boxSub3: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
    },
    imgLogo: {
        width: 140,
        height: 106,
    },
    imgMobile: {
        width: 400,
        height: 'auto',
    },
    boxImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    question: {
        fontSize: 48,
        fontFamily: 'Muli',
        fontWeight: '400',
        // lineHeight: '72px',
        color: '#000000',
        letterSpacing: '-0.015em',
        textAlign: 'left',
    },
    questionQuery: {
        fontSize: 30,
        fontFamily: 'Muli',
        fontWeight: '400',
        lineHeight: '30px',
        color: '#000000',
        letterSpacing: '-0.015em',
        marginTop: 20,
        marginBottom: 10,
    },
    response: {
        fontSize: 21,
        fontFamily: 'Muli',
        fontWeight: '400',
        // lineHeight: '30px',
        letterSpacing: '-0.015em',
        marginBottom: 20,
        color: 'rgba(117, 112, 110, 0.8)',
        textAlign: 'left',
    },
    responseQuery: {
        fontSize: 15,
        fontFamily: 'Muli',
        fontWeight: '400',
        // lineHeight: '30px',
        letterSpacing: '-0.015em',
        marginBottom: 20,
        color: 'rgba(117, 112, 110, 0.8)',
    },
    boton2 :{
        fontWeight: '600',
        fontFamily: 'Muli',
        fontSize: 14,
        height: 40,
        background: '#FFB500',
        textAlign: 'center',
        width: 'fit-content',
        borderRadius: 6,
        alignSelf: 'flex-end',
        boxShadow: 'none',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    boxInfo: {
        display: 'flex', 
        // margin: '0px 5px',
        alignItems: 'center',
        textAlign: 'right',
    },
    boxInfoQuery: {
        // display: 'flex', 
        // margin: '0px 5px',
        alignItems: 'center',
        textAlign: 'center',
    },
    laptop: {
        maxWidth: '100%',
        height: 'auto',
    },
    laptopQuery: {
        width: 300,
        height: 'auto',
    },
    boxMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxMobileQuery: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
    },
}));

const SectionFour = () => {
    const classes = useStyles();
    const location = useLocation();
    const laptopScreen = useMediaQuery('@media (max-width: 1024px)');
    const fullScreen = useMediaQuery('@media (max-width: 960px)');
    
    const handleTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

	return (
		<div className={ laptopScreen ? classes.rootLaptop : classes.root}>
            <CssBaseline />
            <Container style={{ width: fullScreen ? '80%' : '100%'}}>
                <Grid container xs={12} sm={12} lg={12}> 
                    <Grid xs={12} sm={6} lg={6} className={ fullScreen ? classes.boxMobileQuery : classes.boxMobile}>
                        <img src='assets/laptop.png' alt='laptop' className={ fullScreen ? classes.laptopQuery : classes.laptop} />
                    </Grid>
                    <Grid xs={12} sm={6} lg={6} className={ fullScreen ? classes.boxInfoQuery : classes.boxInfo}>
                        <Grid container>
                            <Grid xs={12} sm={12} lg={12}>
                                <Typography className={ fullScreen ? classes.questionQuery : classes.question}>Gestiona tu menú al instante en el panel administrativo.</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} lg={12}>
                                <Typography className={ fullScreen ? classes.responseQuery : classes.response}>
                                    Administra tu menú de platos en un panel de administración.
                                    Procesa tus ordenes, agrega tus productos en secciones, 
                                    categorías y entiende el comportamiento de tus clientes 
                                    en WhatsApp.
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={12} lg={12}>
                                <Button 
                                    variant="contained" 
                                    className={classes.boton2} 
                                    href={location.pathname === "/landing" ? '' : "https://aliados.reunidos.app/register"}
                                    target={location.pathname === "/landing" ? null : "_blank"}
                                    onClick={ location.pathname === "/landing" ? handleTop : ''}
                                >
                                    {location.pathname === "/landing" ? 'Empezar' : 'Comenzar ahora'}
                                </Button>
                            </Grid>
                        </Grid>        
                    </Grid>
                </Grid>
            </Container>
		</div>
	);
}
export default SectionFour;