import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Footer from '../../components/Footer';
import Politicas from './Politicas';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}))
function Privacidad() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Politicas/>
            <Footer/>
        </div>
    )
}

export default Privacidad;