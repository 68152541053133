import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { Link, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles((theme) => ({
  priceCustom: {
    color: '#FFFFFF',
    background: 'transparent',
    border: 'solid 1px #FFB500',
    fontFamily: "Muli",
    fontWeight: "bold",
    fontSize: 18,
    textDecoration: "none",
    height: 40,
    width: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    alignSelf: 'center',
    borderRadius: 6,
    "&:hover": {
      cursor: "pointer",
      background: '#000',
      color: '#FFB500',
      textDecoration: "none",
    },
    backgroundColor: "transparent",
    textAlign: "center",
  },
  textActive: {
    borderBottom: "1px solid #FFAD00",
    color: "white",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      backgroundColor: 'rgba(80, 80, 80, .5)',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  botonTransparent :{
    color: '#FFFFFF',
    background: 'transparent',
    border: 'solid 1px #FFB500',
    fontWeight: '500',
    fontSize: 14,
    fontFamily: 'Muli',
    height: 40,
    alignSelf: 'center',
    borderRadius: 6,
    textAlign: 'center',
    width: 'fit-content',
    '&:hover': {
      background: '#000',
      color: '#FFB500',
    },
  },
  botonNormal: {
    color: "#FFFFFF",
    background: "#FFB500",
    border: "solid 1px #FFB500",
    fontSize: 14,
    fontFamily: "Muli",
    fontWeight: 'bold',
    height: 40,
    alignSelf: "center",
    borderRadius: 6,
    textAlign: 'center',
    width: 'fit-content',
    '&:hover': {
      background: '#000',
      color: '#FFB500',
    },
  },
  menuButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogHeader(props) {
  const classes = useStyles();
  const location = useLocation();
  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      TransitionComponent={Transition}
      className={classes.dialog}
      fullScreen
    >
      <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu" onClick={props.toggle}>
        <CloseRoundedIcon color='primary' style={{ fontSize: 50 }} />
      </IconButton>
      <Typography
        className={classes.priceCustom}
        classes={{
          root: location.pathname === "/precios" ? classes.textActive : null,
        }}
        component={Link}
        to="/precios"
        onClick={props.toggle}
      >
        Precios
      </Typography>
      <Button
        variant="contained"
        className={classes.botonTransparent}
        href="https://aliados.reunidos.app/login"
        target="_blank"
        onClick={props.toggle}
        style={{ marginTop: 10 }}
      >
        iniciar sesión
      </Button>
      <Button
        variant="contained"
        className={classes.botonNormal}
        href="https://aliados.reunidos.app/register"
        target="_blank"
        style={{ marginTop: 20 }}
        onClick={props.toggle}
      >
        comenzar ahora
      </Button>
    </Dialog>
  );
}

export default DialogHeader;
