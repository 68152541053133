import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // height: '100%',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        // paddingTop: '5%',
        paddingBottom: '5%',
        flexDirection: 'column',

        backgroundImage: 'url(assets/bgPrimary.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        animation: '$shrink 5s infinite alternate',
    },
    '@keyframes shrink': {
        '0%': {
            backgroundSize: '110% 110%',
        },
        '100%': {
            backgroundSize: '100% 100%',
        },
    },
    rootCard: {
        width: '95%',
        maxHeight: 800,
        overflowY: 'auto',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
    },
    title: { 
        color: '#FFF', 
        fontWeight: 'bold', 
        fontSize: 30,
        textAlign: 'center',
    }
}))

const Politicas = () => {
    const classes = useStyles();
    const fullScreen = useMediaQuery('@media (max-width: 450px)');
    return (
        <div className={classes.root}>
            <p className={classes.title} style={{marginTop: fullScreen ? 110 : 80}}>Políticas y privacidad</p>
            <Card className={classes.rootCard}>
                <CardContent>
                    
                    <Typography variant="h5" component="h2" style={{textAlign: 'justify'}}>
                    POLÍTICA PARA EL TRATAMIENTO DE DATOS PERSONALES<br />

                    </Typography>
                    <Typography className={classes.pos} color="textSecondary" style={{textAlign: 'justify'}}>
                    Fecha: 10/01/2020
                    </Typography>
                    <Typography variant="body2" component="p" style={{textAlign: 'justify'}}>
                        


REUNIDOS COLOMBIA S.A.S. (en adelante “REUNIDOS” o la “Compañía”) es una sociedad legalmente constituida bajo la legislación colombiana con domicilio en la CR 105 15 B 45 OF 302 de la ciudad de Santiago de Cali, e identificada con NIT 901410921. Prestamos soluciones tecnológicas integrales tendientes a facilitar, gestionar, administrar y efectuar transacciones por medios virtuales y plataformas de comercio electrónico, mediante el desarrollo de actividades en el sector de la informática y tecnología. Para más información sobre nuestros servicios, te pedimos consultar nuestros “TÉRMINOS Y CONDICIONES DE USO” publicados en nuestra página web reunidos.app
<br /><br />
1.	Alcance de la Política La política para el tratamiento de datos personales (la “Política”) que se describe a continuación será aplicable a la recolección, uso, Transmisión y/o Transferencia nacional o internacional, o cualquier otro tipo de tratamiento de Datos Personales que realice REUNIDOS, en calidad de Responsable del tratamiento, sobre los Datos Personales de los Usuarios de nuestras plataformas y la página web.
 Con el objetivo de dar cumplimiento a la legislación vigente en materia de protección de datos, en especial la Ley No. 1581 de 2012 (y demás normas que la modifiquen, adicionen, complementen o desarrollen) y al Decreto No. 1377 de 2013, a continuación, te ponemos al tanto de los aspectos relevantes en relación con la recolección, uso, Transmisión y Transferencia (nacional e internacional), en caso de aplicar, de Datos Personales que REUNIDOS realiza de tus Datos Personales, en virtud de la ley aplicable o la autorización otorgada por ti para adelantar dicho tratamiento, así como también el manejo de los mismos.
 En esta Política encontrarás los lineamientos corporativos y de ley bajo los cuales la Compañía realiza el tratamiento de tus datos, las finalidades, tus derechos como Titular, así como los procedimientos internos y externos para el ejercicio de tales derechos.
 Conforme a lo previsto en el artículo 15 de la Constitución Política de Colombia y la legislación aplicable (Ley No. 1266 de 2008, Ley No. 1581 de 2012, Decreto No. 1377 de 2013 y todas aquellas normas que las reglamenten, adicionen, deroguen o modifiquen), tenemos una clara política de privacidad y protección de tus Datos Personales: no obtenemos información personal de terceros que tengan una relación comercial o jurídica con la Compañía, incluyéndote, a menos que estos la hayan suministrado voluntariamente mediante su consentimiento previo, expreso e informado, cuando así lo establezca la ley o por lo menos que hayan tenido conocimiento mediante un aviso de privacidad de los Datos Personales tratados por la Compañía, salvo que los datos personales sean de naturaleza pública y por ende no necesitamos de tu autorización.
 <br /><br />
2.	Definiciones Para la interpretación de esta Política, te pedimos tener en cuenta las siguientes definiciones:
○	Comprador: Es cualquier persona natural o jurídica, que, como tú, aceptó los T&C de las plataformas digitales de REUNIDOS con el propósito de hacer uso de los Servicios de REUNIDOS para conectar y crear órdenes por bienes y/o servicios de Comercios que hacen uso de los servicios de REUNIDOS.
○	Los Comercios: Son personas jurídicas que previamente se han vinculado con REUNIDOS y sus Servicios a través de un contrato para el procesamiento y gestión de pagos de los bienes y/o servicios que le ofrecen a los consumidores/Compradores.
○	Usuario: Toda persona natural o jurídica que reciba la prestación de los servicios REUNIDOS por parte de la Compañía en virtud de una relación contractual/obligacional, en este caso los Compradores y los Comercios;
○	Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables;
○	Datos Sensibles: Aquellos datos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación;
○	Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta de la Compañía como Responsable de los datos;
○	Política de Tratamiento o Política: Se refiere al presente documento, como política de tratamiento de Datos Personales aplicada por la Compañía de conformidad con los lineamientos de la legislación vigente en la materia y especialmente dirigida a los Usuarios de las plataformas de REUNIDOS;
○	Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos, para efectos de esta Política, ejercerá como Responsable, en principio, la Compañía;
○	Titular: Persona natural cuyos Datos Personales sean objeto de Tratamiento, en este caso los Usuarios de las plataformas digitales de REUNIDOS dedicadas al comercio electrónico;
○	Transferencia: Se refiere al envío por parte de la Compañía como Responsable del Tratamiento o un Encargado de los Datos Personales, a un tercer agente o persona natural/jurídica (receptor), dentro o fuera del territorio nacional para el tratamiento efectivo de Datos Personales;
○	Trasmisión: Se refiere a la comunicación de datos personales por parte del Responsable al Encargado, ubicado dentro o fuera del territorio nacional, para que el Encargado, por cuenta del Responsable, trate los datos personales transmitidos;
○	Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión. 
○	Para el entendimiento de los términos que no se encuentran incluidos dentro de estas definiciones, deberás remitirte a la legislación vigente, en especial a la Ley 1581 de 2012 y al Decreto 1377 de 2013, dando el sentido utilizado en dicha norma a los términos de cuya definición exista duda alguna.
○	Servicios REUNIDOS: Es el sistema que REUNIDOS pone a tu disposición con todos sus desarrollos presentes y futuros para generar ordenes en los comercios que están suscritos a la plataforma de REUNIDOS.
<br /><br />
3.	Uso y finalidades del Tratamiento de los Datos Personales de los Usuarios La Compañía reconoce que tú como Titular de los Datos Personales sujetos a Tratamiento, tienes derecho a la protección de tus datos personales, teniendo en todo caso en cuenta tus responsabilidades, y los derechos y obligaciones con la Compañía. Como Titular de los Datos Personales nos autorizas expresamente a realizar el Tratamiento de tus Datos Personales, entendido como cualquier operación o conjunto de operaciones sobre Datos Personales, incluida pero no limitada a la recolección, almacenamiento, uso, circulación o supresión, conforme a los términos de la presente Política. Así mismo, con la aceptación de esta Política y el debido otorgamiento de tu autorización por medio del “clic de aceptación” nos autorizas a consultar, complementar y actualizar tus Datos Personales en cualquier momento, directamente o con otras bases de datos manejadas por terceros con la autorización también de estos últimos o desde aquellas bases de Datos Personales de naturaleza pública.
 En general, REUNIDOS mantendrá tus Datos Personales hasta por 5 años desde el último tratamiento, o será almacenada por el tiempo necesario para el cumplimiento de las finalidades previstas en esta Política, así como las obligaciones legales y/o contractuales a las que haya lugar conforme a las normas contables y de comercio.
 Te informamos que una vez cese la necesidad de tratamiento de tus datos, los mismos podrán ser eliminados de las bases de datos de la Compañía o archivados en términos seguros a efectos de que solamente sean divulgados cuando a ello hubiere lugar de acuerdo con la ley.
 REUNIDOS podrá subcontratar a terceros para el procesamiento de determinadas funciones o información. Cuando efectivamente subcontratamos con terceros el procesamiento de su información personal o proporcionamos su información personal a terceros prestadores de servicio, advertimos a dichos terceros sobre la necesidad de proteger dicha información personal con medidas de seguridad apropiadas, les prohibimos el uso de su información personal para fines propios y les impedimos que divulguen su información personal a otros. En caso de querer conocer puntualmente quienes actúan como Encargados o Responsables Receptores, favor ponerse en contacto con la Compañía a través del siguiente correo electrónico: protecciondedatos@reunidos.app
 De igual forma la Compañía podrán transferir o transmitir (según corresponda) sus Datos Personales a otras compañías en el extranjero, entre otras, por razones de seguridad, eficiencia administrativa y mejor servicio, para la ejecución de un contrato, de conformidad con las autorizaciones de cada una de estas personas. Bajo este entendido, sus datos podrán ser transmitidos o transferidos, según corresponda, para la culminación de operaciones administrativas a favor y bajo instrucciones de REUNIDOS para la correcta prestación de los Servicios REUNIDOS. En caso de querer conocer puntualmente quienes actúan como Encargados o Responsables Receptores, favor ponerse en contacto con la Compañía a través del siguiente correo electrónico: protecciondedatos@reunidos.app
 En caso de Transmisiones internacionales, REUNIDOS suscribirá los contratos pertinentes, en los términos que exige el Decreto No. 1377 de 2013, y demás legislación vigente en Colombia sobre la materia.
 En caso de Transferencia internacional de datos, REUNIDOS no solicitará la autorización cuando ésta se encuentre amparada en alguna de las excepciones previstas en la Ley y sus decretos reglamentarios.
 <br /><br />
4.	Finalidades del Tratamiento Las finalidades descritas a continuación serán aplicables a todos los Usuarios que hayan autorizado de forma previa, expresa e informada el Tratamiento de sus Datos Personales. Los Datos Personales administrados por REUNIDOS serán tratados para los siguientes propósitos o finalidades:
○	Crear, activar y mantener tu cuenta de Usuario REUNIDOS;
○	Dar cumplimiento a obligaciones contraídas con el Titular;
○	Brindar información sobre cambios en los servicios REUNIDOS prestados;
○	Evaluar la calidad del servicio, realizar estudios de mercado y hacer análisis estadísticos;
○	Enviar información comercial, publicitaria o promocional a través de correo electrónico, teléfono o mensajes de texto sobre servicios o eventos nuestros o de nuestros Comercios;
○	Realización de encuestas y sondeos generales que tengan por objeto determinar opiniones sobre un bien o servicio;
○	Mejorar nuestros servicios y plataformas;
○	Prevenir fraudes en contra de ti y contra REUNIDOS;
○	Proveer soporte técnico y resolver consultas, peticiones, quejas, reclamos que sean realizadas por Titulares u organismos de control;
○	Permitir que compañías vinculadas a REUNIDOS, con las cuales ha celebrado contratos que incluyen disposiciones para garantizar la seguridad y el adecuado Tratamiento de los Datos Personales tratados, contacten al Titular con el propósito de ofrecerle bienes o servicios de su interés;
○	Cualquier otra actividad de naturaleza similar, conexa y/o complementaria a las anteriormente descritas, además de otros fines derivados de los Términos y Condiciones de uso de las plataformas.
○	Utilizar los distintos servicios correspondientes a sitios web, incluyendo descargas de contenidos y formatos;
○	Informar sobre cambios sustanciales en la Política adoptada por REUNIDOS.
○	Establecer y gestionar la relación precontractual y contractual comercial, laboral, civil y cualquier otra índole que surja en virtud del cumplimiento de una obligación legal o contractual a cargo de REUNIDOS;
○	Validar la información del titular para crear una cuenta bancaria en la plataforma de pagos de REUNIDOS;
○	Procesar y analizar la información de los usuarios para fines promocionales de eventos y nuevos servicios y/o productos financieros, u otros fines relacionados con el comercio electrónico.
○	Transferir o transmitir sus Datos Personales a entidades y/o autoridades judiciales y/o administrativas, cuando estos sean requeridos en relación con su objeto y necesarios para el cumplimiento de sus funciones legales o contractuales;
5.	Si nos proporcionas Datos Personales, nos autorizas para usar esta información para las finalidades señaladas en concordancia con lo previsto en esta Política, y no procederemos a transferir o divulgar la misma fuera de nuestras bases de datos salvo que (i) nos autorices a hacerlo, (ii) sea necesario para permitir a nuestros contratistas, proveedores o agentes prestar los servicios que les hemos encomendado, (iii) la utilicemos nosotros o terceros para proporcionar nuestros servicios, (iv) sea entregada a las entidades que prestan servicios de marketing en nuestro nombre o a otras entidades con las cuales tenemos acuerdos de mercadeo conjunto, (v) tenga relación con una fusión, consolidación, adquisición, desinversión u otro proceso de restructuración, (vi) implementemos un contrato de Transmisión de Datos Personales en los términos del Decreto No. 1377 de 2013, o (vii) según sea requerido o permitido por la ley o para las finalidades desarrolladas en la presente Política.
<br /><br />
6.	WPA REUNIDOS Dentro del uso de la Plataforma Tecnológica el Comercio utilizará la aplicación web progresiva de REUNIDOS, herramienta que le genera un valor agregado a los servicios ofrecidos.
<br /> 5.1. ¿Cómo opera REUNIDOS dentro del funcionamiento de comercio electrónico?
REUNIDOS es una WPA (Web Progresive APP) que genera un link personalizado a cada comercio. El comercio podrá compartir con quien quisiera este link, que le permite a sus cliente y a quienes tengan acceso a él para conocer productos y/o servicios ofrecidos por el comercio, así como realizar órdenes y hacer preguntas directamente al comercio.
Por otro lado el Comercio puede acceder a la zona administrativa de la plataforma para poder editar y actualizar - en cualquier momento en que la plataforma se encuentre disponible - la información relacionada con su cuenta como por ejemplo, pero sin limitarse a, nombre de la tienda, productos, posts, imagenes, horarios de atención, datos personales del dueño de la tienda, entre otros. 
<br /><br />
7.	Información recolectada Para el ejercicio del tratamiento y las finalidades descritas en el aparte segundo de esta Política, es posible que recolectamos la siguiente información personal de los Usuarios de nuestras plataformas:
○	Nombres y apellidos;
○	Número de identificación;
○	Número de teléfono;
○	Ciudad y Dirección de domicilio;
○	Segmento económico;
○	Salario;
○	Productos financieros o información de métodos de pago (Por ejemplo, número de tarjeta, fecha de vencimiento, entidad bancaria, entre otros);
○	Correo electrónico;
○	Dirección IP;
○	Histórico de transacciones realizadas a través de los servicios de REUNIDOS.
<br /><br />
8.	También podemos recolectar tu información personal a través de cookies, web beacons u otras tecnologías, cuando usas nuestras plataformas y/o Página Web. El tipo de información a recolectar puede incluir la siguiente:
○	Nombre de dominio;
○	Tipo de navegador y sistema operativo;
○	Páginas web visitadas por los Usuarios;
○	Vínculos seleccionados por los Usuarios;
○	Cantidad de tiempo de visitas por los Usuarios;
○	Vínculos seleccionados por los Usuarios;
○	Cantidad de tiempo de la visita a la Página Web o Plataforma;
○	Actividades de los Usuarios en el Página Web;
○	URL de procedencia o páginas web que llevó a los Usuarios al Página Web;
○	Identificación de dispositivo o identificación alternativa cuando sea requerido por el proveedor de la plataforma;
○	Instalaciones y desinstalaciones; y.
○	Detalles de transacciones y tu historia.
<br /><br />
9.	Tratamiento de datos sensibles En desarrollo de nuestra actividad comercial, para propósitos específicos REUNIDOS realiza tratamiento de Datos Sensibles.
 REUNIDOS sólo efectuará tratamiento de Datos Personales Sensibles en tanto haya sido previamente autorizado por el respectivo Titular y los tratará bajo estándares de seguridad y confidencialidad correspondientes a su naturaleza. Para este fin, REUNIDOS ha implementado medidas administrativas, técnicas y jurídicas, de obligatorio cumplimiento para sus empleados y, en tanto sea aplicable, a sus proveedores, compañías vinculadas y aliados comerciales.
 La recolección de Datos Personales Sensibles en todo caso es de carácter facultativo y no constituye condición para acceder a ninguno de nuestros Servicios REUNIDOS, salvo que sea estrictamente necesario para la prestación de nuestros servicios o por mandato legal.
 <br /><br />
10.	Datos personales de menores de edad En cuantos a los Datos Personales recolectados a través de las plataformas y/o la página web de REUNIDOS, la Compañía no recolecta Datos Personales de menores de edad, debido a que los Servicios REUNIDOS están dirigidos exclusivamente a mayores de edad (mayores de 18 años), en caso de tener alguna duda al respecto, te recomendamos consultar a través del correo electrónico protecciondedatos@reunidos.app
<br /><br />
11.	Derechos de los titulares de los datos personales tratados por REUNIDOS De conformidad con lo establecido en el artículo 8 de la Ley No. 1581 de 2012, como Titular de los Datos personales, con relación a los mismos, tienes de derecho a:
○	Conocer, actualizar y rectificar sus Datos Personales frente a REUNIDOS;
○	Solicitar prueba de la autorización otorgada a REUNIDOS;
○	Ser informado por REUNIDOS, previa solicitud, respecto del uso que le ha dado a sus Datos Personales;
○	Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley 1581 de 2012 y sus reglamentaciones, una vez haya agotado el trámite de consulta o reclamo ante REUNIDOS;
○	Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento efectuado por REUNIDOS no se respeten los principios, derechos y garantías constitucionales y legales;
○	Acceder en forma gratuita a sus Datos Personales que hayan sido objeto de Tratamiento.
<br /><br />
12.	Estos derechos podrán ser ejercidos, bien por el Titular o sus causahabientes acreditados como tal, por su representante o apoderado, o por estipulación a favor de otro.
<br /><br />
13.	Procedimientos para acceder, consultar, rectificar y actualizar su información Como Titular de los Datos Personales tratados por REUNIDOS o como persona autorizada para ello, tienes derecho a acceder a tus Datos Personales y a los detalles del Tratamiento, así como a rectificarlos y actualizarlos en caso de ser inexactos o a solicitar su eliminación cuando consideres que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al Tratamiento de los mismos para fines específicos.
<br />
A continuación, reseñamos los procedimientos que debes surtir para ejercitar tus derechos respecto del tratamiento de datos ante REUNIDOS:
○	Consultas: El Titular podrá consultar de forma gratuita sus Datos Personales al menos una vez cada mes calendario. Para tal fin, podrá elevar una solicitud indicando la información que desea conocer, a través de cualquiera de los canales dispuestos por REUNIDOS.
 La petición será atendida por REUNIDOS en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. Cuando no fuere posible atender la petición dentro de dicho término, este hecho se informará al solicitante, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.
○	Reclamos: De conformidad con lo establecido en el Artículo 14 de la ley 1581 de 2012, cuando el Titular o sus causahabientes consideren que la información tratada por REUNIDOS deba ser objeto de corrección, actualización o supresión, o cuando deba ser revocada por advertirse el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley, podrán presentar una solicitud ante REUNIDOS, la cual será tramitada bajo las siguientes reglas:
 (1) El Titular o sus causahabientes deberán acreditar su identidad, la de su representante, la representación o estipulación a favor de otro o para otro. Cuando la solicitud sea formulada por persona distinta del Titular y no se acredite que la misma actúa en representación de aquél, se tendrá por no presentada.
 (2) La solicitud de rectificación, actualización, supresión o revocatoria debe ser presentada a través de los medios habilitados por REUNIDOS indicados en el presente documento y contener, como mínimo, la siguiente información:
■	El nombre y domicilio del Titular o cualquier otro medio para recibir la respuesta.
■	Los documentos que acrediten la identidad del solicitante y en caso dado, la de su representante con la respectiva autorización.
■	La descripción clara y precisa de los Datos Personales respecto de los cuales el Titular busca ejercer alguno de los derechos y la solicitud concreta.
○	(3) El término máximo para atender esta solicitud será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atenderla dentro de dicho término, se informará al interesado sobre los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.
<br /><br />
14.	Con el fin de ejercer el derecho al Habeas Data, los Titulares podrán contactarse directamente a través de la dirección de correo electrónico especificado anteriormente lo cual se tramitará de conformidad con la Ley.
 También podrá comunicarse a la nuestra dirección física también mencionada en el siguiente acápite.
 <br /><br />
15.	Canales de atención El área responsable de atender las peticiones, consultas o reclamos respecto del tratamiento de tus Datos Personales será nuestra área de tratamiento de datos. Las vías que hemos implementado para garantizar el ejercicio de tus derechos a través de la presentación de la solicitud respectiva son:
○	Dirección electrónica: protecciondedatos@reunidos.app
<br /><br />
16.	Seguridad de la información En desarrollo del principio de seguridad establecido en la Ley 1581 de 2012, REUNIDOS ha adoptado las medidas técnicas, humanas y administrativas necesarias para proteger la seguridad a los Datos Personales y Sensibles, objeto de tratamiento, en aras de evitar su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
 En el marco de sus relaciones con terceros que conlleve el tratamiento de Datos Personales y Sensibles estará amparado por un acuerdo de confidencialidad, integridad y privacidad entre las partes que demanda la protección y preservación de los datos compartidos entre las partes. De igual forma, los terceros deberán comprometerse a contar con estándares iguales o superiores a los de REUNIDOS en materia de seguridad de la información. En cualquier caso, los terceros tendrán la obligación de dar cumplimiento a las políticas y manuales de seguridad de REUNIDOS, así como a nuestros protocolos como si les fueran propios.
 <br /><br />
17.	Vigencia Esta Política de Tratamiento entra en vigencia a partir del mes de octubre de 2020. En cualquier caso, podremos modificar o actualizar esta Política, en caso de que sea necesario, difundiendo los nuevos términos a través de la página web o la aplicación.
  


                    <br />
                      
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}

export default Politicas;