import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import News from './News2'

import Footer from '../../components/Footer'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
		height: '100vh',
    },
}))

function News2() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <News />
            <Footer />
        </div>
    )
}

export default News2;