import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Typography,
    Grid,
    useMediaQuery
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
        // height: '100vh',
        paddingTop: '5%',
        paddingBottom: '5%',
    },
    first: {
        fontSize: 52,
        fontWeight: '400',
        fontFamily: 'Muli',
        color: '#FFB500',
        textAlign: 'center',
        marginBottom: '5%',
    },
    firstQuery: {
        fontSize: 24,
        fontWeight: '400',
        fontFamily: 'Muli',
        color: '#FFB500',
        textAlign: 'center',
        marginBottom: 0,
    },
    container: {
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
    },
    infoImport: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '32%'
    },
    titleInfo: {
        fontWeight: '500',
        fontSize: 24,
        letterSpacing: '-0.015em',
        color: '#000000',
        marginBottom: 0,
    },
    titleInfoQuery: {
        fontWeight: '500',
        fontSize: 20,
        letterSpacing: '-0.015em',
        color: '#000000',
        marginBottom: 0,
    },
    subInfo: {
        fontSize: 18,
        fontWeight: '400',
        fontFamily: 'Muli',
        letterSpacing: '-0.015em',
        color: '#75706E',
        marginTop: 20,
    },
    subInfoQuery: {
        fontSize: 15,
        fontWeight: '400',
        fontFamily: 'Muli',
        letterSpacing: '-0.015em',
        color: '#75706E',
        marginBottom: 0,
    },
    boxAll: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    boxAllQuery: {
        width: '80%',
        margin: '0px auto',
        textAlign: 'center',
        alignItems: 'center',
    },
    boxInfo: {
        maxWidth: '70%',
        margin: 40,
    },
    boxInfoQuery: {
        maxWidth: '90%',
        margin: '10px auto',
    }
}));

const SectionTwo = (props) => {
    const classes = useStyles();
    const fullScreen = useMediaQuery('@media (max-width: 414px)');

	return (
		<div className={classes.root}>
            <Typography className={ fullScreen ? classes.firstQuery : classes.first}>Preguntas frecuentes</Typography>
            <Grid container style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                <Grid xs={12} sm={6} lg={6}>
                    <Grid container /* direction="column" */ className={fullScreen ? classes.boxAllQuery : classes.boxAll}>
                        <Grid xs={12} sm={4} lg={4} className={ fullScreen ? classes.boxInfoQuery : classes.boxInfo}>
                            <Typography gutterBottom className={ fullScreen ? classes.titleInfoQuery : classes.titleInfo}>
                                ¿Hay descuentos por volúmen de ordenes?
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : classes.subInfo}>
                            ¡Tu mismo! Tú gestionas los domicilios por cada una de
                            tus sucursales, podrás poner el valor de tus domicilios 
                            por sucursal desde el panel administrativo.
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={4} lg={4} className={ fullScreen ? classes.boxInfoQuery : classes.boxInfo}>
                            <Typography gutterBottom className={ fullScreen ? classes.titleInfoQuery : classes.titleInfo}>
                                ¿Qué es un menú de WhatsApp?
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : classes.subInfo}>
                                El menú WhatsApp es tu propia tienda en línea. 
                                Puedes transformar cada sucursal de tu restaurante, 
                                en un receptor de pedidos WhatsApp. Cada sucursal 
                                tendrá un número propio de WhatsApp.
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={4} lg={4} className={ fullScreen ? classes.boxInfoQuery : classes.boxInfo}>
                            <Typography gutterBottom className={ fullScreen ? classes.titleInfoQuery : classes.titleInfo}>
                                Tengo más de 1.000 ordenes al mes, ¿Hay tarifas especiales?
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : classes.subInfo}>
                                ¡Por supuesto! Puedes debes contactarnos 
                                para acceder a valores por orden más favorables.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={6} lg={6}>
                    <Grid container /* direction="row" */ className={fullScreen ? classes.boxAllQuery : classes.boxAll}>
                        <Grid xs={12} sm={4} lg={4} className={ fullScreen ? classes.boxInfoQuery : classes.boxInfo}>
                            <Typography gutterBottom className={ fullScreen ? classes.titleInfoQuery : classes.titleInfo}>
                                ¿Cuánto duran sus contratos?
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : classes.subInfo}>
                                Es mensual y puedes cancelar o renovar tu cuenta siempre 
                                que quieras. al final de cada mes te llegará una factura 
                                con el valor fijo de tu tienda + el variable del total de 
                                órdenes generadas a través de tu pagina web.
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={4} lg={4} className={ fullScreen ? classes.boxInfoQuery : classes.boxInfo}>
                            <Typography gutterBottom className={ fullScreen ? classes.titleInfoQuery : classes.titleInfo}>
                                ¿Qué es una orden de compra?
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : classes.subInfo}>
                                Cuando un comprador ingresa a tu tienda y esta interesado en comprar, 
                                Reunidos envía a tu WhatsApp una orden de compra lista para cerrar en 
                                una venta con toda la información del comprador y de los platos que se 
                                encuentra interesado. Estos datos también los encuentras en tu panel de 
                                administración para que puedas hacerle seguimiento.
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={4} lg={4} className={ fullScreen ? classes.boxInfoQuery : classes.boxInfo} style={{ marginTop: 0 }}>
                            <Typography gutterBottom className={ fullScreen ? classes.titleInfoQuery : classes.titleInfo}>
                                ¿Puedo usar Reunidos fuera de Colombia?
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : classes.subInfo}>
                                Por el momento, Reunidos se encuentra disponible para vender 
                                sólo en Colombia. Muy pronto estará disponible a nivel mundial.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
		</div>
	);
}
export default SectionTwo;