export default {
  // baseUrl: 'http://localhost:5000/api/v1/',
  baseUrl: 'https://api.dev.reunidos.app/api/v1/',
  endpoints: {
    tarifas: 'tariff/by_country/',
    user_sus: 'user_sus',
    user_sus_search: 'user_sus/email/',
    cities: 'cities',
  },
};
