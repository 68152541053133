import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'

import Footer from '../../components/Footer'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
		height: '100vh',
		backgroundImage: 'url(assets/bgPrimaryPrice.png)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
    },
}))

function Prices() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <SectionOne />
            <SectionTwo />
            <Footer />
        </div>
    )
}

export default Prices;