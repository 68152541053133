import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Typography,
    TextField,
    MenuItem,
    Paper,
    IconButton,
    Button,
    Radio,
    Checkbox,
    CircularProgress,
    useMediaQuery,
} from '@material-ui/core';
import { Link, useLocation } from "react-router-dom";
// import axios from 'axios';
import Select from 'react-select';
import { saveData, getCountryDropdown, searchEmail } from './funtions/formulario.actions';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import BlockIcon from '@material-ui/icons/Block';
import { useHistory } from "react-router-dom";
// import history from '../../../history'
// import CheckIcon from '@material-ui/icons/Check';
// import api from '../../services/constants/api'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    customTextField: {
        width: '100%',
        marginTop: 20,
        '& .MuiOutlinedInput-root': {
            borderRadius: 15,
        },
        '& .MuiOutlinedInput-input': {
            padding: ' 16px 16px',
        },
    },
    customTextFieldPhone: {
        width: '100%',
        marginTop: 20,
        '& .MuiOutlinedInput-root': {
            borderTopRightRadius: 15,
            borderBottomRightRadius: 15,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        '& .MuiOutlinedInput-input': {
            padding: ' 16px 16px',
        }
    },
    inputbot: {
        display: 'none',
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 51,
        cursor: 'pointer'
    },
    inputCustom: {
        display: 'flex',
        padding: '0px 0px 0px 16px',
        height: 51,
        cursor: 'pointer'
    },
    valueContainer: {
        display: 'flex',
        textAlign: 'center',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        padding: ' 12px 0px 12px 16px',
        cursor: 'pointer',

        '& .MuiTypography-root': {
            fontSize: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    valueContainerPhone: {
        display: 'flex',
        textAlign: 'center',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        padding: ' 0px 0px',
        cursor: 'pointer',

        '& .MuiTypography-root': {
            fontSize: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    placeholder: {
        position: 'absolute',
        left: 15,
        bottom: 13,
        fontSize: '14px',
        cursor: 'pointer'
    },
    paper: {
        zIndex: 99,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        position: 'absolute',
    },
    selectCustomStyle: {
        width: '100%',
        marginTop: 15,
        '& .MuiOutlinedInput-root': {
            paddingLeft: '8px !important',
            borderRadius: 15,
        },
    },
    selectCustom: {
        width: '100%',
        marginTop: 15,
        '& .MuiOutlinedInput-root': {
            borderRadius: 15,
        },
    },
    selectCustomIndicativo: {
        width: '65%',
        marginTop: 19,
        height: 'fit-content',
        alignSelf: 'flex-end',
        '& .MuiOutlinedInput-root': {
            paddingLeft: '8px !important',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 15,
            borderBottomLeftRadius: 15,
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
    boxInputs: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '85%',
    },
    boxRadioCheck: {
        '&:focus': {
            outline: 0,
        }
    },
    btnAcept: {
        // color: '#FFB500',
        background: '#FFB500',
        fontWeight: 'bold',
        fontSize: 14,
        fontFamily: 'Muli',
        height: 40,
        alignSelf: 'center',
        borderRadius: 6,
        marginTop: 20,
        // marginRight: 20,
        marginBottom: 10,
        boxShadow: 'none',
        textAlign: 'center',
        width: '100%',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: 'solid 1px #FFB500',
        },
    },
    textInfo: {
        color: '#7d7977',
        fontSize: '12',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'left',
    },
    textTerms: {
        color: '#7d7977',
        fontSize: '12',
        textAlign: 'left',
        marginTop: 6,
    },
    snackCustom: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: '#43a047',
            display: 'flex',
            flexDirection: 'row',
            '& .MuiSnackbarContent-message': {
                width: '80%',
            },
            '& .MuiSnackbarContent-action': {
                width: '20%',
                paddingLeft: 0,
            }
        },
    },
    snackCustomError: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: '#d32f2f',
            display: 'flex',
            flexDirection: 'row',
            '& .MuiSnackbarContent-message': {
                width: '80%',
            },
            '& .MuiSnackbarContent-action': {
                width: '20%',
                paddingLeft: 0,
            }
        },
    },
    textOpcional: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 15,
        marginBottom: 13,
        color: '#7d7977',
    }
}));

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

NoOptionsMessage.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    selectProps: PropTypes.object.isRequired,
};


function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

Option.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
};

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

Menu.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
};


const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};
const componentsCustom = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
};

const GlobalRadio = withStyles({
    root: {
        color: "#a2aaad",
        padding: "0px 0px 0px 0px",
        "&$checked": {
            color: "#302926",
            padding: "0px 0px 0px 0px",
        },
    },
    checked: {},
})((props) => <Radio color="#302926" {...props} />);

function Formulario(props) {
    // console.log('Formulario llego  *******************', props)
    const history = useHistory();
    const classes = useStyles();
    const fullScreen = useMediaQuery('@media (max-width: 768px)');
    const [stateGlobal, setStateGlobal] = useState(false);
    const [emailCheck, setEmailCheck] = useState(false);
    const [checked, setChecked] = useState(false);
    const [emailMsg, setEmailMsg] = useState(true);
    const [country, setCountry] = useState(null);
    const [emailValidate, setEmailValidate] = useState(null);
    const [form, setForm] = useState({
        id: '',
        name: '',
        address: '',
        email: '',
        indicative: { 
            value: 57,
            label: <MenuItem key={1} className={classes.valueContainerPhone}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={`assets/flags/CO.png`}
                            alt=' '
                            style={{ width: 20, marginRight: 3 }}
                        />
                        {`+57`}
                    </div>
                </MenuItem>,
        },
        phone: '',
        branch: '',
        logistic: false,
    });
    const [radio, setRadio] = useState({
        radio1: false,
        radio2: true,
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = snackbar;

    useEffect(() => {
        async function getCountry() {
            try {
                const countryDropDown = await getCountryDropdown();
                setCountry(countryDropDown);
            } catch (error) {
                // console.log('eorr al traer paises', error)
            }
        };
        getCountry();
    }, [])

    async function verifyEmail(textEmail){
        try {
            const dataEmail = await searchEmail(textEmail);
            if(dataEmail){
                setEmailValidate(false)
                setEmailMsg(false)
            } else {
                setEmailValidate(true)
                setEmailMsg(false)
            }
        } catch (error) {
            // console.log('erorr al traer email', error)
        } 
    };

    const handleRadio = (type) => () => {
        if (type === "radio1") {
            setRadio({ radio1: true, radio2: false })
            setForm({...form, logistic: true })
        } else {
            setRadio({ radio1: false, radio2: true })
            setForm({...form, logistic: false })
        }
    };
    const handleChangeForm = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value });
    };
    const handleCheck = () => {
        setChecked(!checked);
    };
    const handleSnackbar = (newState, text, color) => {
        setSnackbar({ open: true, ...newState, message: text, color });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    function handleCountry(value) {
        setForm({ ...form, id: value });
    }
    function handleIndicative(value) {
        setForm({ ...form, indicative: value });
    }
    function handleBranch(value) {
        setForm({ ...form, branch: value });
    }
    function handlePhone(value) {
        setForm({ ...form, phone: value.target.value });
    }
    function validateNumber(value) {
        const re = /^[0-9\b]+$/;
        if (value.target.value === '' || re.test(value.target.value)) {
            handlePhone(value)
        }
    }

    const onChangeEmail = (event) => {
        const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const t = event.currentTarget.value.split(" ");
        let tex = ''
        t.map((r) => {
            if (r !== "") {
                tex += r
            }
        })
        setForm({ ...form, email: tex })
        if(regExp.test(tex)){
            setEmailMsg(true);
            handleChangeForm(tex);
            setEmailCheck(false)
            verifyEmail(tex)
        }else{
            setEmailCheck(true)
        }
    }

    

    const createData = async () => {
        if (form.id === '' || form.name === '' || form.address === '' || form.email === '' || form.indicative === '' || form.phone === '' || form.branch === '') {
            setStateGlobal(true)
            handleSnackbar({ vertical: 'top', horizontal: 'center', autoHideDuration: 2000 }, 'Campos obligatorios', false)
        } else {
            if (form.phone.length < 8) {
                handleSnackbar({ vertical: 'top', horizontal: 'center', autoHideDuration: 2000 }, 'ERROR : ¡Debe ser un número válido para WhatsApp!', false)
            } else if (emailCheck) {
                handleSnackbar({ vertical: 'top', horizontal: 'center', autoHideDuration: 2000 }, 'ERROR : ¡Debes ingresar un correo válido!', false)
            } else if (checked === false) {
                handleSnackbar({ vertical: 'top', horizontal: 'center', autoHideDuration: 2000 }, 'Debes aceptar los términos y condiciones', false)
            } else if (emailValidate === false) {
                handleSnackbar({ vertical: 'top', horizontal: 'center', autoHideDuration: 2000 }, 'ERROR : Este email ya está registrado, por favor espere a que nuestro asesor se comunique con usted', false)
            } else {

                const result = await saveData(form)
                if(result) {
                    handleSnackbar({ vertical: 'top', horizontal: 'center', autoHideDuration: 2000 }, 'Registro éxitoso', true)
                    setStateGlobal(false)
                    handleCheck(false)
                    handleRadio(false)
                    setEmailCheck(false)
                    setForm({
                        id: '',
                        name: '',
                        address: '',
                        email: '',
                        indicative: { 
                            value: 57,
                            label: <MenuItem key={1} className={classes.valueContainerPhone}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={`assets/flags/CO.png`}
                                                alt=' '
                                                style={{ width: 20, marginRight: 3 }}
                                            />
                                            {`+57`}
                                        </div>
                                    </MenuItem>,
                        },
                        phone: '',
                        branch: '',
                        logistic: false,
                    })
                    setRadio({ radio1: false, radio2: true })
                    history.push({
                        pathname: '/contacted',
                        state: { checked: checked },
                    });
                   
                }
            }
        }
    }

    const restaurants = [
        { value: '0', label: 'Ninguna (iniciando el negocio)' },
        { value: '1', label: 'De 1 a 5' },
        { value: '2', label: 'De 6 a 10' },
        { value: '3', label: 'De 11 a 20' },
    ];

    const countryOption = country ? country.map((resp1, i) => ({
        value: resp1,
        label: <MenuItem key={i} className={classes.valueContainerPhone}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`assets/flags/${resp1.cnt_code}.png`}
                    alt=' '
                    style={{ width: 20, marginRight: 4 }}
                />
                {resp1.cnt_name}
            </div>
        </MenuItem>,
    })) : [];

    const phoneOption = country ? country.map((resp1, i) => ({
        value: resp1.cnt_indicativo,
        label: <MenuItem key={i} className={classes.valueContainerPhone}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`assets/flags/${resp1.cnt_code}.png`}
                    alt=' '
                    style={{ width: 20, marginRight: 3 }}
                />
                {`+${resp1.cnt_indicativo}`}
            </div>
        </MenuItem>,
    })) : [];

    const renderEmail = () => {
        if(emailMsg){
            return  <div style={{ display: 'flex', alignSelf: 'flex-start',}}>
            <CircularProgress size={20} style={{ color: '#FFB500'}} />
            <p style={{ fontSize: 14, marginLeft: 3, color: '#FFB500' }}>verificando...</p>
        </div>
        }else{
            if (!emailValidate) {
                return <div style={{ display: 'flex', alignSelf: 'flex-start',}}>
                    <BlockIcon size={16} style={{ color: 'red'}} />
                    <p style={{ fontSize: 14, marginLeft: 3, color: 'red' }}>¡Ya existe!</p>
                </div>
            } /* else {
                return  <div>
                            <p style={{ fontSize: 14, marginLeft: 3, color: '#FFB500' }}>Disponible</p>
                        </div>
            } */
        }
    }
    return (
        <div className={classes.root}>
            <Typography style={{ fontWeight: 'bold', fontSize: 32, textAlign: fullScreen ? 'center' : 'left', width: '85%' }}>¡Comienza aqui!</Typography>
            <div className={classes.boxInputs}>
                <Select
                    classes={classes}
                    className={classes.selectCustom}
                    name="country"
                    placeholder="País"
                    noOptionsMessage={() => '- selecione -'}
                    TextFieldProps={{
                        label: '',
                        InputLabelProps: {
                            htmlFor: 'react-select-country',
                            shrink: true,
                        },
                        variant: 'outlined',
                        error: stateGlobal ? form.id === '' : null
                    }}
                    options={countryOption}
                    components={components}
                    value={form.id}
                    onChange={handleCountry}
                />
                <TextField
                    className={classes.customTextField}
                    label=""
                    id="restaurante"
                    name="restaurante"
                    type='text'
                    variant="outlined"
                    placeholder='Nombre del restaurante'
                    value={form.name}
                    onChange={handleChangeForm('name')}
                    required
                    autoComplete='off'
                    error={stateGlobal ? form.name === '' : null}
                />
                <TextField
                    className={classes.customTextField}
                    label=""
                    placeholder='Dirección del restaurante'
                    type="text"
                    id="direccion"
                    name="direccion"
                    variant="outlined"
                    value={form.address}
                    onChange={handleChangeForm('address')}
                    required
                    autoComplete='off'
                    error={stateGlobal ? form.address === '' : null}
                />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <TextField
                        className={classes.customTextField}
                        label=""
                        placeholder='Correo electrónico del dueño'
                        type="email"
                        id="email"
                        name="email"
                        variant="outlined"
                        value={form.email}
                        onChange={onChangeEmail}
                        required
                        autoComplete='off'
                        error={emailCheck ? true : stateGlobal ? form.email === '' : null}
                        helperText={emailCheck ? '¡ingrese un correo valido!' : null}
                    />
                    {form.email ? renderEmail() : ''}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <Select
                        classes={classes}
                        className={classes.selectCustomIndicativo}
                        name="indicative"
                        placeholder="+57"
                        noOptionsMessage={() => '- selecione -'}
                        TextFieldProps={{
                            label: '',
                            InputLabelProps: {
                                htmlFor: 'react-select-indicative',
                                shrink: true,
                            },
                            variant: 'outlined',
                            error: stateGlobal ? form.indicative === '' : null
                        }}
                        options={phoneOption}
                        components={componentsCustom}
                        value={form.indicative}
                        onChange={handleIndicative}
                    />
                    <TextField
                        className={classes.customTextFieldPhone}
                        label=""
                        placeholder='Escribe tu número de celular'
                        type="text"
                        id="direccion"
                        name="direccion"
                        variant="outlined"
                        value={form.phone}
                        onChange={validateNumber}
                        required
                        autoComplete='off'
                        error={stateGlobal ? form.phone === '' : null}
                        inputProps={{
                            maxLength: 10
                        }}
                    />
                </div>
                <Select
                    classes={classes}
                    className={classes.selectCustomStyle}
                    name="branch"
                    placeholder="Número de puntos de venta"
                    noOptionsMessage={() => '- selecione -'}
                    TextFieldProps={{
                        label: '',
                        InputLabelProps: {
                            htmlFor: 'react-select-branch',
                            shrink: true,
                        },
                        variant: 'outlined',
                        error: stateGlobal ? form.branch === '' : null
                    }}
                    options={restaurants}
                    components={componentsCustom}
                    value={form.branch}
                    onChange={handleBranch}
                />
                <div>
                    <div className={classes.textOpcional}>
                        <Typography style={{ fontSize: 12, textAlign: 'center', marginLeft: 6 }}>¿Tengo domiciliarios y logística propia?</Typography>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                                <GlobalRadio
                                    onChange={handleRadio("radio1")}
                                    color="default"
                                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                                    // style={{ marginLeft: 4, }}
                                    required={true}
                                    checked={radio.radio1}
                                    error={stateGlobal ? checked === false : handleCloseSnackbar}
                                />
                                <Typography style={{ fontSize: 12, textAlign: 'left' }}>Si</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 5, marginLeft: 6 }}>
                                <GlobalRadio
                                    onChange={handleRadio("radio2")}
                                    color="default"
                                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                                    style={{ marginLeft: 4, }}
                                    required={true}
                                    checked={radio.radio2}
                                    error={stateGlobal ? checked === false : handleCloseSnackbar}
                                />
                                <Typography style={{ fontSize: 12, textAlign: 'left' }}>No</Typography>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.textInfo}>
                            <Checkbox
                                onClick={handleCheck}
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                style={{ padding: 0, }}
                                required={true}
                                checked={checked}
                                error={stateGlobal ? checked === false : handleCloseSnackbar}
                            />
                            <Typography style={{ fontSize: 12, marginLeft: 6 }}>
                                Al continuar, acepto que Reunidos entre en contacto conmigo
                                por WhatsApp, e-mail o teléfono.
                            </Typography>
                        </div>
                        <div className={classes.textTerms}>
                            <Typography style={{ fontSize: 13, textAlign: 'left' }}>
                                Al hacer click en <b>"Empezar"</b> aceptas los <Typography component={Link} to="/terminos-condiciones" target='_blank' style={{ color: '#FFB500', textDecoration: 'underline', fontSize: 13 }}>Términos y condiciones</Typography> y
                                la <Typography component={Link} to="/terminos-condiciones" target='_blank' style={{ color: '#FFB500', textDecoration: 'underline', fontSize: 13 }}>Política de Privacidad</Typography>.
                            </Typography>
                            <Button
                                variant="contained"
                                className={classes.btnAcept}
                                onClick={createData}
                                disabled={form.id === '' || form.name === '' || form.address === '' || form.email === '' || form.indicative === '' || form.phone === '' || form.branch === ''}
                            >
                                Empezar
                            </Button>
                            {/* <Button variant="contained" className={classes.btnAcept} onClick={verifyEmail}>
                                Empezar2222
                            </Button> */}
                            <Snackbar
                                anchorOrigin={{ vertical, horizontal }}
                                open={open}
                                onClose={handleCloseSnackbar}
                                autoHideDuration={2000}
                                message={snackbar.message}
                                key={vertical + horizontal}
                                className={snackbar.color ? classes.snackCustom : classes.snackCustomError}
                                action={[
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        onClick={handleCloseSnackbar}
                                    >
                                        <CloseIcon style={{ width: 20, height: 20 }} />
                                    </IconButton>
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Formulario;