import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    // Button,
    Typography,
    Grid,
    useMediaQuery,
    Hidden,
    Card,
    CardContent
} from '@material-ui/core';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Formulario from './Formulario';

const useStyles = makeStyles((theme) => ({
    boxMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    boxMobileQuery: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 105,
    },
    containerText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'right',
    },
    mobile: {
        width: '80%',
        // height: 'auto',
    },
    mobileQuery: {
        width: 200,
        // height: 'auto',
    },
    mobileIphone: {
        width: 150,
        paddingBottom: 20
        // height: 'auto',
    },
    title:{
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 56,
        fontFamily: 'Muli',
        lineHeight: '80px',
        textAlign: 'center',
        // marginBottom: 20,
    },
    titleIphone:{
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: 'Muli',
        textAlign: 'center',
    },
    titlePhone6:{
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: 'Muli',
        lineHeight: '20px',
        // marginBottom: 10,
        textAlign: 'center',
    },
    titlePortatil:{
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 40,
        fontFamily: 'Muli',
        lineHeight: '66px',
        // marginBottom: 10,
        textAlign: 'center',
    },
    titleQuery:{
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 30,
        fontFamily: 'Muli',
        lineHeight: '66px',
        // marginBottom: 10,
        textAlign: 'center',
    },
    fullInfo: {
        display: 'flex', 
        maxWidth: '80%',
    },
    fullInfoQuery: {
        display: 'flex', 
        width: '100%'
    },
    progress: {
        color: '#FFB500',
		'& .MuiCircularProgress-colorPrimary': {
			color: '#FFB500',
		},
    },    
    cardForm: {
        width: '90%',
        margin: 'auto',
        borderRadius: 50,
    },
    cardFormQuery: {
        width: '100%',
        margin: 'auto',
        borderRadius: 50,
    },
    contentForm: {
        padding: '30px 40px',
        [theme.breakpoints.down('xs')]: {
            padding: '20px 0px',
        },
    },
}));

const URL = 'https://cw9v27zz62.execute-api.us-east-1.amazonaws.com/production/getCountry'

function Info(props) {
    const classes = useStyles();
    const screenPortatil = useMediaQuery('@media (max-width: 1360px)');
    const portatilMedium = useMediaQuery('@media (max-width: 1280px)');
    const fullScreen = useMediaQuery('@media (max-width: 1024px)');
    const sizeText = useMediaQuery('@media (max-width: 480px)');
    const iphoneScreen = useMediaQuery('@media (max-width: 320px)');
    const iphone6Screen = useMediaQuery('@media (max-width: 375px)');
    const [country, setCountry] = React.useState('')
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

	React.useEffect(() => {
		async function fetch() {
			try {
				const result = await axios.get(URL)
                // console.log('result', result.data.country)
                const img = result.data.country
                await require(`../../../../public/assets/modelPhone/${img}.png`) 
                setCountry(img)
                setLoading(false)
			} catch (error) {
                // console.log('error', error)             
                setCountry('CO')
                setLoading(false)
			}
		}
		fetch()
	}, [])

    return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Grid container className={ fullScreen ? classes.fullInfoQuery : classes.fullInfo}>
                <Grid xs={12} sm={12} lg={6} className={ fullScreen ? classes.boxMobileQuery : classes.boxMobile}>
                    {/* <Hidden mdUp> */}
                        <Typography className={ iphoneScreen ? classes.titleIphone : iphone6Screen ? classes.titlePhone6 : fullScreen ? classes.titleQuery : screenPortatil ? classes.titlePortatil : classes.title} style={{ lineHeight: sizeText ? '25px' : '60px', marginTop: 29, marginBottom: sizeText ? 17 : '' }}>
                            Pedidos online, 0% comisión
                        </Typography>
                        <Typography style={{color: '#fff', fontSize: 20, marginBottom: 15, textAlign: 'center', lineHeight: sizeText ? '22px' : '', padding: 5}}>
                            ¡Ofrece tu menú WhatsApp y crea un 
                            canal de pedidos en línea propio!
                        </Typography>
                    {/* </Hidden> */}
                    <Hidden smDown>
                        <div style={{width: portatilMedium ? '45%' : '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {loading ?
                                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <CircularProgress size={80} className={classes.progress}/>
                                </div>
                            : 
                                // <img src={img} alt='foto movil' className={ iphoneScreen ? classes.mobileIphone : fullScreen ? classes.mobileQuery : classes.mobile} />
                                <img
                                    src={`assets/modelPhone/${country}.png`}
                                    className={ iphoneScreen ? classes.mobileIphone : fullScreen ? classes.mobileQuery : classes.mobile}
                                    alt="telefono"
                                />
                            }
                        </div>
                    </Hidden>
                </Grid>
                <Grid xs={12} sm={12} lg={6} className={classes.containerText}>
                    <Card className={iphone6Screen ? classes.cardFormQuery : fullScreen ? classes.cardForm : classes.cardForm}>
                        <CardContent className={classes.contentForm}>
                            <div>
                                <Formulario {...props} />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} sm={12} lg={6} className={classes.containerText}>
                    <Hidden mdUp>
                        <div style={{width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                            {loading ?
                                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <CircularProgress size={80} className={classes.progress}/>
                                </div>
                            : 
                                // <img src={img} alt='foto movil' className={ iphoneScreen ? classes.mobileIphone : fullScreen ? classes.mobileQuery : classes.mobile} />
                                <img
                                    src={`assets/modelPhone/${country}.png`}
                                    className={ iphoneScreen ? classes.mobileIphone : fullScreen ? classes.mobileQuery : classes.mobile}
                                    alt="telefono"
                                />
                            }
                        </div>
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    );
}
export default Info;