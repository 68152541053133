import React/* , { useState, useEffect } */ from 'react'

import Informacion from './Informacion'
import { makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '5%',
        paddingBottom: '5%',

        backgroundImage: 'url(assets/bgPrimary.jpg)',
        backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'cover',
        animation: '$shrink 5s infinite alternate',
    },
    rootQuery: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 20,

        backgroundImage: 'url(assets/bgPrimary.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    '@keyframes shrink': { 
        '0%': {
            backgroundSize: '110% 110%',
        },
        '100%': {
            backgroundSize: '100% 100%',
        },
    },
}))
function Home(props) {
    const classes = useStyles();
    const tabletScreen = useMediaQuery('@media (max-width: 768px)');

    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/9012103.js';
    script.id = 'hs-script-loader'
    script.type = 'text/javascript'
    script.async = true;
    script.defer = true;
    document.getElementById('root').appendChild(script);
    
    return (
        <div className={ tabletScreen ? classes.rootQuery : classes.root}>
            <Informacion />
        </div>
    )
}

export default Home;