import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    useMediaQuery,
    Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // height: '100vh',
        paddingTop: '5%',
        paddingBottom: '5%',
    },
    first: {
        fontSize: 52,
        fontWeight: '400',
        fontFamily: 'Muli',
        color: '#FFB500',
        textAlign: 'center',
        marginBottom: '5%',
    },
    firstQuery: {
        fontSize: 24,
        fontWeight: '400',
        fontFamily: 'Muli',
        color: '#FFB500',
        textAlign: 'center',
        marginBottom: 0,
    },
    container: {
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
    },
    infoImport: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '32%'
    },
    titleInfo: {
        fontWeight: '500',
        fontSize: 24,
        letterSpacing: '-0.015em',
        color: '#000000',
        marginBottom: 0,
    },
    titleInfoQuery: {
        fontWeight: '500',
        fontSize: 20,
        letterSpacing: '-0.015em',
        color: '#000000',
        marginBottom: 0,
    },
    subInfo: {
        fontSize: 18,
        fontWeight: '400',
        fontFamily: 'Muli',
        textAlign: 'justify',
        letterSpacing: '-0.015em',
        color: '#75706E',
        marginTop: 20,
    },
    subInfoQuery: {
        fontSize: 15,
        fontWeight: '400',
        fontFamily: 'Muli',
        textAlign: 'justify',
        letterSpacing: '-0.015em',
        color: '#75706E',
        marginBottom: 0,
    },
    subInfoTips: {
        fontSize: 18,
        fontWeight: '400',
        fontFamily: 'Muli',
        textAlign: 'justify',
        letterSpacing: '-0.015em',
        color: '#75706E',
        marginTop: 10,
    },
    boxAll: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    boxAllQuery: {
        width: '80%',
        margin: '0px auto',
        textAlign: 'center',
        alignItems: 'center',
    },
    boxInfo: {
        maxWidth: '70%',
        margin: 40,
    },
    boxInfoQuery: {
        maxWidth: '90%',
        margin: '10px auto',
    },
    botonColor :{
        color: '#FFFFFF',
        background: '#FFB500',
        fontWeight: '500',
        fontSize: 14,
        fontFamily: 'Muli',
        marginBottom: 10,
        height: 40,
        alignSelf: 'center',
        borderRadius: 6,
        boxShadow: 'none',
        textAlign: 'center',
        width: 'fit-content',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
}));

const SectionNews2 = (props) => {
    const classes = useStyles();
    const fullScreen = useMediaQuery('@media (max-width: 414px)');

    return (
        <div className={classes.root}>
            <br/><br/><br/><br/><br/>
            <Typography className={fullScreen ? classes.firstQuery : classes.first}>Si vendes comida por WhatsApp debes saber esto</Typography>
            <br/>
            <Grid container style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                <Grid xs={12} sm={12} lg={12}>
                    <Grid container>
                        <Grid xs={12} sm={12} lg={12} style={{ width: '100%' }}>
                            <Typography gutterBottom className={fullScreen ? classes.titleInfoQuery : classes.titleInfo}>
                                5 Estrategias para restaurantes en pandemia. Mira esto.
                            </Typography>
                            <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                                Un restaurante puede tener la mejor comida de la ciudad, estar ubicado en el paraíso, incluso brindar precios de ensueño, pero una estrategia de ventas que siempre funciona es que tu equipo sepa representar a los clientes con el valor del negocio. Entonces, antes de que un cliente se enamore del restaurante, lo ideal es que sus empleados vendan con gusto las especialidades de ese local que realizan todos los días con tanto esfuerzo.
                            </Typography>
                            <br/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                <Grid xs={12} sm={12} lg={12} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <img width="60%" style={{ marginBottom: 30, alignSelf: 'center' }} src="assets/ventas-por-whatsapp.PNG" />
                </Grid>
                <Grid xs={12} sm={12} lg={12}>
                    <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                       Y es que si nos ponemos en el comedor, sin duda una de las cosas que más nos motiva a volver al restaurante es que las personas que están ahí hacen que nuestra estadía sea cómoda, placentera, y sentimos que la inversión realizada valió la pena
                        Primero, para establecer algunas características básicas, se debe tener en cuenta la gestión óptima de los restaurantes:
                    </Typography>
                <br />  
                    

                    <ul>
                        <li>
                        <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                            Carácter amable, extrovertido, con sentido común, y sobre todo mucha astucia e intuición para saber moderar el nivel de atención según el cliente. Sobre todo si hablamos del personal que se encuentra en la sala, lo ideal es que tenga la capacidad de brindar al comensal exactamente lo que necesita, que empatice con el humor y estilo de los clientes, ya que esto creará el ambiente adecuado para mejorar las estrategias de venta.
                        </Typography>

                        <br />  
                            
                        </li>
                        <li>
                        <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                        	Todo el equipo debe reconocer los objetivos principales de la empresa. Realizar reuniones ordinarias para informar qué platos deben venderse especialmente, las promociones para poder abastecerse de manera adecuada. Un cuerpo de trabajadores que conoce a la perfección el producto que ofrece estará preparado para seducir con éxito a los clientes. De hecho, cuanto mayor sea la profesionalidad del camarero en lo que ofrece, más probabilidades hay de que los clientes vuelvan.
                        </Typography>

                        
                          
                        </li>
                    </ul>

                    <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                        Y las dos partes, el equipo y la dirección, ya podrían haber establecido una secuencia de servicios. Marcar un mapa donde se definan todos los momentos clave para impulsar las ventas (al ofrecer aperitivos, al tomar el pedido, al ofrecer postres ...).
                        Cuidando estos detalles iniciales, el restaurante puede estar preparado para poner en práctica una serie de trucos que permitirán que las ventas aumenten exponencialmente.

                        Por último dejamos una serie de “tips” que pueden ayudar a mejorar las ventas de su negocio de cara a esta pandemia.
                    </Typography>
                    <br /><br />
                    <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                        1. Cree un menú de comida para take away. Cree un menú separado para comida para llevar y conviértalo en una versión simplificada de su menú para cenar. Elija artículos que sean fáciles de preparar y que viajen bien. 
                        Evite los artículos delicados que puedan deshacerse o derretirse durante el transporte.
                    </Typography>
                    
                    <br />
                    <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                        2. Proporcionar un servicio de recogida en restaurante. Para obtener el servicio de comida para llevar más rápido, puede proporcionar servicio de recogida a sus clientes. Si tiene un estacionamiento, reserve un par de espacios cerca de su entrada. Capacite a su personal sobre los procedimientos adecuados para manejar pedidos.
                    </Typography>
                    
                    <br />
                    <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                        3. Implementar servicios de entrega. Si ha estado pensando en configurar servicios de entrega a domicilio para su restaurante, ahora es el momento perfecto. Ni siquiera necesita su propia flota de entrega gracias a la disponibilidad de servicios confiables de entrega de terceros como Mensajeros Urbanos. Cuando sus clientes no puedan acudir a usted, puede adaptarse llevando sus servicios a sus hogares.
                    </Typography>
                    
                    <br />
                    <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                        4. Optimice su ventanilla de drive-thru. Si ya tiene una ventanilla para autoservicio, mantenga el área abastecida con los artículos que necesita para atender a los clientes rápidamente. Las servilletas, los cubiertos de plástico y los condimentos deben mantenerse a la vista para que nunca se olviden. 
                        Pruebe sus altavoces para asegurarse de que funcionen al nivel adecuado y de que los clientes puedan oírle con claridad. 
                        También puede trabajar con su equipo de autoservicio para establecer expectativas sobre cuánto tiempo deberían tomar los pedidos en completarse.
                    </Typography>
                    
                    <br />
                    <Typography variant="body2" gutterBottom className={fullScreen ? classes.subInfoQuery : classes.subInfo}>
                        5. Establezca pedidos en línea. Actualice el sitio web de su restaurante para proporcionar pedidos en línea de comida para llevar, entrega y servicio de autoservicio. A muchos clientes les resulta más fácil realizar pedidos en línea en lugar de hacerlo por teléfono, ya que pueden realizar pedidos al instante en cualquier momento que deseen. Puede acelerar todo el proceso aceptando también el pago en línea.
                    </Typography>
                    
                    <br />
                </Grid>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Button variant="contained" className={classes.botonColor} href="https://aliados.reunidos.app/register" target="_blank">
                        comenzar ahora
                    </Button>
                </div>
            </Grid>
        </div>
    );
}
export default SectionNews2;