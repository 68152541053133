import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
        // height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        paddingRight: '10%',
        paddingLeft: '10%',
        paddingTop: '5%',
        paddingBottom: '8%',
    },
    firstText: {
        width: '90%',
        alignSelf: 'center'
        // margin: '0px auto',
    },
    first: {
        fontWeight: '400',
        fontFamily: 'Muli',
        fontSize: 36,
        color: '#FFB500',
        marginTop: 20,
        textAlign: 'left'
    },
    firstQuery: {
        fontWeight: '400',
        fontFamily: 'Muli',
        fontSize: 20,
        marginTop: 80,
        textAlign: 'center',
        color: '#FFB500',
    },
    firstIphone: {
        fontWeight: '400',
        fontFamily: 'Muli',
        fontSize: 20,
        marginTop: 50,
        textAlign: 'center',
        color: '#FFB500',
    },
    second: {
        fontFamily: 'Muli',
        fontSize: 22,
        fontWeight: '400',
        color: '#75706E',
        marginTop: 20,
        marginBottom: 20
    },
    secondQuery: {
        fontFamily: 'Muli',
        fontSize: 12,
        fontWeight: '400',
        color: '#75706E',
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 1200,
        boxShadow: 'none',
    },
    boxAll: {
        height: 250,
        alignItems: 'center',
    },
    boxAllQuery: {
        width: '80%',
        margin: '0px auto',
        height: 250,
        alignItems: 'center',
    },
    img: {
        // margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    imgQuery: {
        // margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    infoImport: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    titleInfo: {
        fontWeight: '500',
        fontSize: 24,
        fontFamily: 'Muli',
        letterSpacing: '-0.015em',
        color: '#000000'
    },
    titleInfoLaptop: {
        fontWeight: '500',
        fontSize: 20,
        fontFamily: 'Muli',
        letterSpacing: '-0.015em',
        color: '#000000'
    },
    titleInfoQuery: {
        fontWeight: '500',
        fontSize: 16,
        fontFamily: 'Muli',
        letterSpacing: '-0.015em',
        color: '#000000',
        marginLeft: 5, 
        lineHeight: '18px'
    },
    subInfo: {
        fontSize: 18,
        fontFamily: 'Muli',
        fontWeight: '400',
        letterSpacing: '-0.015em',
        color: '#75706E',
    },
    subInfoLaptop: {
        fontSize: 14,
        fontFamily: 'Muli',
        fontWeight: '400',
        letterSpacing: '-0.015em',
        color: '#75706E',
    },
    subInfoQuery: {
        fontSize: 12,
        fontFamily: 'Muli',
        fontWeight: '400',
        letterSpacing: '-0.015em',
        color: '#75706E',
        marginLeft: 5,
    },
    boton2 :{
        fontWeight: '600',
        fontSize: 14,
        fontFamily: 'Muli',
        height: 40,
        background: '#FFB500',
        textAlign: 'center',
        width: 'fit-content',
        borderRadius: 6,
        alignSelf: 'flex-end',
        boxShadow: 'none',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    container: {
        marginTop: 20,
        width: '90%',
        alignSelf: 'center'
    }
}));

const SectionTwo = () => {
    const classes = useStyles();
    const location = useLocation();
    const laptopScreen = useMediaQuery('@media (max-width: 1024px)');
    const fullScreen = useMediaQuery('@media (max-width: 960px)');
    const textScreen = useMediaQuery('@media (max-width: 360px)');
    const textIphoneScreen = useMediaQuery('@media (max-width: 375px)');

    const handleTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

    return (
        <div className={classes.root}>
            <div className={classes.firstText}>
                <Typography className={ textIphoneScreen ? classes.firstIphone : fullScreen ? classes.firstQuery : classes.first}>Con Reunidos las órdenes llegan al WhatsApp</Typography>
                <Typography className={ fullScreen ? classes.secondQuery : classes.second}>
                    Todos los pedidos de tus clientes llegan directamente al número de WhatsApp del restaurante o de cada 
                    sucursal. Disfruta de todas las funciones de comercio electrónico con la facilidad y comodidad de WhatsApp.
                </Typography>
            </div>
            <Grid container className={classes.container}>

                <Grid xs={12} sm={6} lg={6}>

                    <Grid container direction="row" className={fullScreen ? classes.boxAllQuery : classes.boxAll}>

                        <Grid xs={3} sm={3} lg={3}>
                            <div>
                                <img className={ fullScreen ? classes.imgQuery : classes.img} alt="complex" src='assets/whatsapp.png' />
                            </div>
                        </Grid>
                        <Grid xs={9} sm={9} lg={9}>
                            <Typography gutterBottom variant="subtitle1" className={ fullScreen ? classes.titleInfoQuery : laptopScreen ? classes.titleInfoLaptop : classes.titleInfo }>
                                Tienda online WhatsApp
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : laptopScreen ? classes.subInfoLaptop : classes.subInfo}>
                                Tus clientes hacen el pedido en tu tienda web 
                                y llegan directamente al WhatsApp de tu negocio.
                            </Typography>
                        </Grid>
                        <Grid xs={3} sm={3} lg={3}>
                            <div>
                                <img className={classes.img} alt="complex" src='assets/graph.png' />
                            </div>
                        </Grid>
                        <Grid xs={9} sm={9} lg={9}>
                            <Typography gutterBottom variant="subtitle1" className={ fullScreen ? classes.titleInfoQuery : laptopScreen ? classes.titleInfoLaptop : classes.titleInfo }>
                                Panel administrativo
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : laptopScreen ? classes.subInfoLaptop : classes.subInfo}>
                                Gestiona tus ventas, clientes y 
                                órdenes recibidas por cada sucursal.
                            </Typography>
                        </Grid>

                    </Grid>
                    
                </Grid>
                <Grid xs={12} sm={6} lg={6}>

                    <Grid container direction="row" className={fullScreen ? classes.boxAllQuery : classes.boxAll}>

                        <Grid xs={3} sm={3} lg={3}>
                            <div>
                                <img className={classes.img} alt="complex" src='assets/cutlery.png' />
                            </div>
                        </Grid>
                        <Grid xs={9} sm={9} lg={9} style={{ marginTop: textScreen ? 40 : ''}}>
                            <Typography gutterBottom variant="subtitle1" className={ fullScreen ? classes.titleInfoQuery : laptopScreen ? classes.titleInfoLaptop : classes.titleInfo }>
                                Platos y productos ilimitados
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : laptopScreen ? classes.subInfoLaptop : classes.subInfo}>
                                Ofrece los platos y productos que desees en 
                                una o varias sucursales si las tienes.
                            </Typography>
                        </Grid>
                        <Grid xs={3} sm={3} lg={3}>
                            <div>
                                <img className={classes.img} alt="complex" src='assets/communication.png' />
                            </div>
                        </Grid>
                        <Grid xs={9} sm={9} lg={9}>
                            <Typography gutterBottom variant="subtitle1" className={ fullScreen ? classes.titleInfoQuery : laptopScreen ? classes.titleInfoLaptop : classes.titleInfo }>
                                Soporte 24/7
                            </Typography>
                            <Typography variant="body2" gutterBottom className={ fullScreen ? classes.subInfoQuery : laptopScreen ? classes.subInfoLaptop : classes.subInfo}>
                                Atención al cliente y soporte 
                                personalizado por llamada o email.
                            </Typography>
                        </Grid>

                    </Grid>

                </Grid>

            </Grid>
            <Button 
                variant="contained" 
                className={classes.boton2} 
                style={{ alignSelf: 'center', marginTop: 50  }}
                href={location.pathname === "/landing" ? '' : "https://aliados.reunidos.app/register"}
                target={location.pathname === "/landing" ? null : "_blank"}
                onClick={ location.pathname === "/landing" ? handleTop : ''}
            >
                {location.pathname === "/landing" ? 'Empezar' : 'Comenzar ahora'}
            </Button>
        </div>
    );
}
export default SectionTwo;