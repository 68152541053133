import axios from 'axios';
import api from '../../../../services/constants/api'

export async function saveData(data) {
    const body = {
        uss_cnt_id: data.id.value.cnt_id,
        uss_rest_name: data.name,
        uss_rest_address: data.address,
        uss_email: data.email,
        uss_phone: data.phone,
        uss_phone_indicativo: data.indicative.value,
        uss_branch: data.branch.value,
        uss_logistic: data.logistic,
    };
    // console.log('body', body)
    const URL = `${api.baseUrl}${api.endpoints.user_sus}`
    try {
        const request = await axios.post(URL, body);
        // console.log('response create user-register', request)
        return request
        
    } catch (error) {
        // console.log('eorrrorrr', error)
        return alert("Error al crear registro");
    }
    // return true
}

export async function getCountryDropdown() {
    try {
        const URL = `${api.baseUrl}${api.endpoints.cities}/country`
        const request = await axios.get(URL);
        return request.data.rows;
    } catch (error) {
        return false
    }
    
}

export async function searchEmail(email) {
    // console.log(email)
    const URL = `${api.baseUrl}${api.endpoints.user_sus_search}${email}`
    try {
        const result = await axios.get(URL)
        // console.log('resultresultresult', result.data.exist)
        return result.data.exist
    } catch (error) {
        // return console.log('errorr al traer', error)
    }
}