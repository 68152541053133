import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Typography,
    Grid,
    useMediaQuery,
    Divider,
    IconButton
} from '@material-ui/core';
import { ReactComponent as SVGILinkedin } from '../Icons/linkedin.svg';
import { ReactComponent as SVGITwitter } from '../Icons/twitter.svg';
import { ReactComponent as SVGIFacebook } from '../Icons/facebook.svg';
import { ReactComponent as SVGIYoutube } from '../Icons/youtube.svg';
import {ReactComponent as SVGLogoDark} from '../Icons/logo-dark.svg';
import InstagramIcon from '@material-ui/icons/Instagram';
import Linking from '@material-ui/core/Link';
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
        // height: '50vh',
        backgroundColor: '#FDF5ED',
        display: 'flex',
        paddingTop: '5%',
        paddingBottom: '5%',
    },
	rootQuery: {
		width: '100%',
        // height: '100vh',
        backgroundColor: '#FDF5ED',
        display: 'flex',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    imgLogo: {
        width: 140,
        height: 'auto',
    },
    imgLogoQuery: {
        width: 80,
        height: 'auto',
    },
    section1: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto 40px',
        height: '40%',
        paddingBottom: 30,
        borderBottom: '1px solid rgba(0,0,0,0.5)',
    },
    subSection1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 10,
    },
    subSection1Query: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        margin: 'auto',
        textAlign: 'center',
        alignItems: 'center',
    },
    boxButtons: {
        // width: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        alignContent: 'center',
        height: 120,
        alignSelf: 'center',
    },
    botonTransparent2 :{
        color: '#000000',
        background: '#fff',
        border: 'solid 1px #FFB500',
        fontWeight: '500',
        fontSize: 14,
        fontFamily: 'Muli',
        height: 40,
        alignSelf: 'center',
        borderRadius: 6,
        textAlign: 'center',
        width: 'fit-content',
        boxShadow: 'none',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
        },
    },
    botonTransparent2Tablet :{
        color: '#000000',
        background: 'transparent',
        border: 'solid 1px #FFB500',
        fontWeight: '500',
        fontSize: 14,
        fontFamily: 'Muli',
        height: 40,
        alignSelf: 'center',
        borderRadius: 6,
        textAlign: 'center',
        width: 'fit-content',
        boxShadow: 'none',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
        },
    },
    elipse: {
        width: 45,
        height: 'auto',
        padding: 3,
        color: '#000000'
    },
    boton2 :{
        fontWeight: '600',
        fontSize: 14,
        fontFamily: 'Muli',
        height: 40,
        background: '#FFB500',
        borderRadius: 6,
        alignSelf: 'center',
        boxShadow: 'none',
        textAlign: 'center',
        width: 'fit-content',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    boton2Tablet :{
        fontWeight: '600',
        fontSize: 14,
        fontFamily: 'Muli',
        height: 50,
        background: '#FFB500',
        borderRadius: 6,
        alignSelf: 'center',
        boxShadow: 'none',
        textAlign: 'center',
        width: 'fit-content',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    boton2Query :{
        fontWeight: '600',
        fontSize: 15,
        fontFamily: 'Muli',
        height: 40,
        background: '#FFB500',
        borderRadius: 6,
        alignSelf: 'center',
        boxShadow: 'none',
        textAlign: 'center',
        width: 'fit-content',
        '&:hover': {
            background: '#000',
            color: '#FFB500',
            border: '1px solid #FFAD00',
        },
    },
    title: {
        fontWeight: 'bold',
        fontSize: 15,
        fontFamily: 'Muli',
        lineHeight: '24px',
        color : '#000000',
        letterSpacing: '-0.015em',
    },
    titleQuery: {
        fontWeight: 'bold',
        fontSize: 15,
        fontFamily: 'Muli',
        lineHeight: '24px',
        color : '#000000',
        letterSpacing: '-0.015em',
    },
    section2: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto 40px',
        paddingTop: 30
    },
    subTitle: {
        fontWeight: '400',
        fontSize: 15,
        fontFamily: 'Muli',
        lineHeight: '24px',
        color: 'rgba(0,0,0,0.8)',
        letterSpacing: '-0.015em',
    },
    subTitleQuery: {
        fontWeight: '400',
        fontSize: 15,
        fontFamily: 'Muli',
        lineHeight: '24px',
        color: 'rgba(0,0,0,0.8)',
        letterSpacing: '-0.015em',
    },
    redes: {
        // width: '25%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    redesQuery: {
        // width: '25%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    boxInfo: {
        width: 376,
    },
    boxInfoQuery: {
        width: '80%',
        margin: 'auto',
    },
    divider: {
        height: 1,
        background: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        margin: '10px auto',
    },
    container: {
        width: '80%',
        margin: '0px auto',
    },
    icon: {
        // marginRight: 6,
        '&:hover': {
            cursor: 'pointer',
            '& path': {
                fill: '#000000',
            }
        },
    },
    BtnRedes: {
        padding: 6
    },
    priceHref: {
        cursor: 'pointer',
        color: '#000',
        '&:hover': {
            color: '#000',
            textDecoration: 'none'
        }
    }
}));

const Footer = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const fullScreen = useMediaQuery('@media (max-width: 425px)');
    const phoneScreen = useMediaQuery('@media (max-width: 550px)');
    const tabletScreen = useMediaQuery('@media (max-width: 768px)');

    const handleTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

	return (
		<div className={ fullScreen ? classes.rootQuery : classes.root}>

            <Grid container className={classes.container}>
                <Grid xs={12} sm={7} lg={7}>
                    <div className={ phoneScreen ? classes.subSection1Query : classes.subSection1}>
                        {/* <img src='assets/logo-dark.png' alt='logo' className={ fullScreen ? classes.imgLogoQuery : classes.imgLogo}/> */}
                        <SVGLogoDark className={ fullScreen ? classes.imgLogoQuery : classes.imgLogo}/>
                    </div>
                </Grid>
                {/* <Grid xs={12} sm={2} lg={2}>
                    <div className={ fullScreen ? classes.subSection1Query : classes.subSection1}>
                        <Typography className={ fullScreen ? classes.titleQuery : classes.title}>Blog</Typography>
                        <Typography className={ fullScreen ? classes.subTitleQuery : classes.subTitle}>Tendencias foodtech ... Soluciones para restaurantes 2020. Pandemia ha cambiado las costumbres...</Typography>
                    </div>
                </Grid> */}
                <Grid xs={12} sm={2} lg={2}>
                    <div className={ tabletScreen ? classes.subSection1Query : classes.subSection1}>
                        <Typography className={ fullScreen ? classes.titleQuery : classes.title}>Compañía</Typography>
                        <div className={ fullScreen ? classes.subTitleQuery : classes.subTitle}>
                            <Typography component={Link} to="/precios" className={classes.priceHref}>Precios</Typography>
                            <br/>
                            <Linking href="/politicas-privacidad" className={classes.priceHref}>Política de tratamiento de datos personales.</Linking>
                            <br/>
                            <Typography component={Link} to="/terminos-condiciones" target='_blank' className={classes.priceHref}>Términos de condiciones</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={3} lg={3}>
                    <div className={classes.boxButtons}>
                        {location.pathname === "/landing" ?
                            null
                         :
                            <Button 
                                variant="contained" 
                                className={ tabletScreen ? classes.botonTransparent2Tablet : classes.botonTransparent2} 
                                href="https://aliados.reunidos.app/login" 
                                target="_blank"
                            >
                                iniciar sesión
                            </Button>
                        }
                        <Button 
                            variant="contained" 
                            className={ fullScreen ? classes.boton2Query : tabletScreen ? classes.boton2Tablet : classes.boton2} 
                            href={location.pathname === "/landing" ? '' : "https://aliados.reunidos.app/register"}
                            target={location.pathname === "/landing" ? null : "_blank"}
                            onClick={ location.pathname === "/landing" ? handleTop : ''}
                        >
                            {location.pathname === "/landing" ? 'Empezar' : 'Comenzar ahora'}
                        </Button>
                    </div>
                </Grid>
                <Grid xs={12} sm={12} lg={12}>
                    <Divider className={classes.divider}/>
                </Grid>
                <Grid xs={12} sm={6} lg={6}>
                    <div className={ fullScreen ? classes.boxInfoQuery : classes.boxInfo}>
                        <Typography className={classes.subTitle}>Reunidos 2020, Todos los derechos reservados. info@reunidos.app</Typography>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} lg={6}>
                    <div className={ fullScreen ? classes.redesQuery : classes.redes}>
                        <Typography className={classes.subTitle}>Siguenos en:</Typography>
                        <IconButton href="https://www.instagram.com/reunidos_app/"  target="_blank" className={classes.BtnRedes}>
                            <InstagramIcon className={classes.icon}/>
                        </IconButton>
                        <IconButton href='https://www.linkedin.com/company/reunidos' target="_blank" className={classes.BtnRedes}>
                            <SVGILinkedin className={classes.icon}/>
                        </IconButton>
                        <IconButton href="https://twitter.com/reunidos_app" target="_blank" className={classes.BtnRedes}>
                            <SVGITwitter className={classes.icon}/>
                        </IconButton>
                        <IconButton href="https://www.facebook.com/reunidosapp"  target="_blank" className={classes.BtnRedes}>
                            <SVGIFacebook className={classes.icon}/>
                        </IconButton>
                        <IconButton target="_blank" className={classes.BtnRedes}>
                            <SVGIYoutube className={classes.icon}/>
                        </IconButton>
                        
                        {/* <YouTubeIcon className={classes.elipse} src='assets/Ellipse.png' alt='logo' /> */}
                    </div>

                </Grid>

            </Grid>
                
   
		</div>
	);
}
export default Footer;