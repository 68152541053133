import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Typography,
    Container,
    Grid,
    useMediaQuery,
    CssBaseline,
} from '@material-ui/core';
import Slider from "react-slick";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
        width: '100%',
        paddingBottom: '8%',
        paddingTop: '8%',
		// height: '50vh',
    },
	rootQuery: {
        width: '100%',
        paddingBottom: '8%',
        paddingTop: '8%',
		// height: '70vh',
    },
    container1: {
        height: '100%',
        maxWidth:' 90%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 30,
    },
    card: {
        width: 200,
        // height: 200,
        outline: 'none',  
        margin: '0 auto', 
        '&:hover':{
            width: 210,
            transition: '0.5s',
        }
    },
    cardPhoneM: {
        width: 200,
        // height: 200,
        outline: 'none',  
        margin: '0 auto',
        '&:hover':{
            width: 210,
            transition: '0.5s',
        } 
    },
    cardIphone: {
        width: 100,
        // height: 200,
        outline: 'none',  
        margin: '0 auto',
        '&:hover':{
            width: 110,
            transition: '0.5s',
        }  
    },
    title: {
        fontWeight: '400',
        fontFamily: 'Muli',
        fontSize: 32,
        letterSpacing: '-0.015em',
        textAlign: 'center',
        color: '#000000',
        marginBottom: 30,
    },
    titleQuery: {
        fontWeight: '400',
        fontFamily: 'Muli',
        fontSize: 24,
        letterSpacing: '-0.015em',
        textAlign: 'center',
        color: '#000000',
        marginBottom: 30,
    },
    scrollHorizontal: {
        height: 220,
        '& .slick-prev': {
            left: 0
        },
        '& .slick-next': {
            right: 0
        },
    },
    scrollHorizontalPhone: {
        height: 125,
        '& .slick-prev': {
            left: 0
        },
        '& .slick-next': {
            right: 0
        },
        // '& ul.slick-dots' : {
        //     position: 'absolute',
        //     bottom: '-45px',
        // }
    },
}));

const Restaurants = () => {
    const classes = useStyles();
    const location = useLocation();
    const laptopScreen = useMediaQuery('@media (max-width: 1024px)');
    const tabletScreen = useMediaQuery('@media (max-width: 768px)');
    const fullScreen = useMediaQuery('@media (max-width: 425px)');
    const PhoneMScreen = useMediaQuery('@media (max-width: 375px)');
    const Carrusel = useMediaQuery('@media (max-width: 320px)');
    
    const [dataImages, setDataImages] = React.useState({
        img1: false,
        img2: false,
        // img3: false,
        img4: false,
    })

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 300,
        slidesToShow: fullScreen ? 1 : tabletScreen ? 1 : laptopScreen? 1 : 3,
        slidesToScroll: 1,
        // centerPadding: "60px",
        centerMode: true,
        className: "center",
    };

    React.useEffect(() => {
        if (tabletScreen) {
            setDataImages({
                img1: false,
                img2: false,
                // img3: false,
                img4: false,
            })
        }
    }, [tabletScreen])

    const onMouse1 = () => setDataImages({...dataImages, img1: false })
    const onMouseEnd1 = () => setDataImages({...dataImages, img1: false })

    const onMouse2 = () => setDataImages({...dataImages, img2: false })
    const onMouseEnd2 = () => setDataImages({...dataImages, img2: false })

    // const onMouse3 = () => setDataImages({...dataImages, img3: false })
    // const onMouseEnd3 = () => setDataImages({...dataImages, img3: false })

    const onMouse4 = () => setDataImages({...dataImages, img4: false })
    const onMouseEnd4 = () => setDataImages({...dataImages, img4: false })
    
	return (
		<div className={ classes.root } style={{background:   location.pathname === "/landing" ? '#FDF5ED' : 'rgba(218, 218, 218, 0.12)'}}>
            <CssBaseline />
            <Container>
                <Grid container spacing={1}>
                    <Grid xs={12} sm={12} lg={12}>
                        <Typography className={ fullScreen ? classes.titleQuery : classes.title}>Restaurantes que ya venden con Reunidos</Typography>
                        <Slider {  ...settings} className={ Carrusel ? classes.scrollHorizontalPhone : classes.scrollHorizontal}>
                            <div className={ Carrusel ? classes.cardIphone : PhoneMScreen ? classes.cardPhoneM : classes.card}>
                                <img src={dataImages.img1 ? 'assets/dolcestellacolor.png' : 'assets/dolcestellagris.png'} className={ Carrusel ? classes.cardIphone : PhoneMScreen ? classes.cardPhoneM : classes.card} alt="logo" onMouseEnter={tabletScreen ? null : onMouse1} onMouseOut={onMouseEnd1}/>
                            </div>
                            <div className={ Carrusel ? classes.cardIphone : PhoneMScreen ? classes.cardPhoneM : classes.card}>
                                <img src={dataImages.img2 ? 'assets/nuestrosuenocolor.png' : 'assets/nuestrosuenogris.png'} className={ Carrusel ? classes.cardIphone : PhoneMScreen ? classes.cardPhoneM : classes.card} alt="logo" onMouseEnter={tabletScreen ? null : onMouse2} onMouseOut={onMouseEnd2}/>
                            </div>
                            {/* <div className={ Carrusel ? classes.cardIphone : PhoneMScreen ? classes.cardPhoneM : classes.card} >
                                <img src={dataImages.img3 ? 'assets/conalmacolor.png' : 'assets/conalmagris.png'} className={ Carrusel ? classes.cardIphone : PhoneMScreen ? classes.cardPhoneM : classes.card} alt="logo" onMouseEnter={tabletScreen ? null : onMouse3} onMouseOut={onMouseEnd3}/>
                            </div> */}
                            <div className={ Carrusel ? classes.cardIphone : PhoneMScreen ? classes.cardPhoneM : classes.card}>
                                <img src={dataImages.img4 ? 'assets/polloapanadocolor.png' : 'assets/polloapanadogris.png'} className={ Carrusel ? classes.cardIphone : PhoneMScreen ? classes.cardPhoneM : classes.card} alt="logo" onMouseEnter={tabletScreen ? null : onMouse4} onMouseOut={onMouseEnd4}/>
                            </div>
                        </Slider>
                    </Grid>
                </Grid>
            </Container>
		</div>
	);
}
export default Restaurants;