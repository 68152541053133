import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
} from '@material-ui/core';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: { 
        fontWeight: 'bold', 
        fontSize: 28, 
        textAlign: 'center', 
        width: '85%',
        color: '#333333',
        '&:hover': {
            color: '#333333',
            textDecoration: 'none'
        }
    },
    textBack: { 
        fontWeight: 'bold', 
        fontSize: 28, 
        textAlign: 'center', 
        width: '85%',
        color: '#FFB500',
        textDecoration: 'underline',
        '&:hover': {
            color: '#FFB500',
        }
    },
}));

function Formulario2(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.title}>¡Hemos recibido tu información!</Typography>
            <img src='assets/ic-green-check.svg' alt='check' style={{maxWidth: 180, maxHeight: 180, marginTop: 20}} />
            <Typography className={classes.title} style={{textAlign: 'justify', fontSize: 22, marginTop: 20}}>Uno de nuestros asesores se pondrá en contacto contigo.</Typography>
            <Typography component={Link} to="/" className={classes.textBack} style={{fontSize: 22, marginTop: 20}}>Ir a la página principal</Typography>
        </div>
    );
}
export default Formulario2;